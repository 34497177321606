import React from "react";
import { Box, Text } from "@chakra-ui/react";

const DevBy = () => {

  return (
    <Box opacity={0.1} pos="fixed" bottom={2} right={2}>
      <Text fontSize="md" fontWeight="light">
        Developed by EdwinPT
      </Text>
    </Box>
  );
};

export default DevBy;

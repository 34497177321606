export const getDownloadFileName = (routePath: string) => {
  switch (routePath) {
  case "/teams-app":
    return "Dock-Contract-TeamsApp-Manifest";
  case "/backend-service":
    return "Dock-Contract-BackendService-Config";
  case "/word-addIn":
    return "Dock-Contract-WordAddIn-Manifest";
  case "/outlook-addIn":
    return "Dock-Contract-OutlookAddIn-Manifest";
  default:
    return "Dock-Contract-Manifest";
  }
};

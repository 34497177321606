import React from "react";
import "./App.css";
import Cards from "./components/Cards";
import Navbar from "./components/nav/Navbar";
import {
  Routes,
  Route,
} from "react-router-dom";
import BackendServicePage from "./pages/backendService/BackendServicePage";
import WordAddInPage from "./pages/wordAddIn/WordAddInPage";
import TeamsAppManifestPage from "./pages/teamsApp/TeamsAppManifestPage";
import AppProvider from "./AppProvider";
import OutlookAddInPage from "./pages/outlookAddIn/OutlookAddInPage";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./const/theme";
import DevBy from "./components/DevBy";
import LoginForm from "./pages/authentication/Login";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
        <Navbar />
        <Routes>
          <Route path="/backend-service" element={<BackendServicePage />} />
          <Route path="/word-addIn" element={<WordAddInPage />} />
          <Route path="/outlook-addIn" element={<OutlookAddInPage />} />
          <Route path="/teams-app" element={<TeamsAppManifestPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<Cards />} />
        </Routes>
      </AppProvider>
      <DevBy/>
    </ChakraProvider>
  );
};

export default App;

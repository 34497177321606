import React, { useContext } from "react";
import { Stack, Text } from "@chakra-ui/react";
import { AppContext } from "../../AppProvider";

const FormHeader: React.FC<IProps> = ({ title }) => {
  const { isDarkMode, isSmall } = useContext(AppContext);
  return (
    <Stack p={2} justifyContent="center">
      <Text
        fontSize={isSmall ? "xl" : "2xl"}
        color={isDarkMode ? "whitesmoke" : "facebook.900"}
        fontWeight="bold"
      >
        {title}
      </Text>
    </Stack>
  );
};

export default FormHeader;

interface IProps {
  title: string;
}
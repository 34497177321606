/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import {
  useBreakpointValue, Flex, Grid, GridItem,
  FormControl, FormLabel, Tooltip, Input, FormErrorMessage, Button, Box, Switch,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import OutputPage from "../../../components/OutputPage";
import { backendServiceTemplateV4_3 } from "../../../const/templates/backendServiceTemplate";
import { regEx, onlyUnique, getReplacerRegex } from "../../../helpers/replacerRegex";
import FormHeader from "../../../components/form/FormHeader";
import FormContainer from "../../../components/form/FormContainer";

const Version4_3_xForm = () => {
  const [values, setValues] = useState({
    RedisBuild: 356,
    BuildId: undefined,
    Origin: "",
    BaseUrl: "",
    SiteUrl: "",
    ClientId: "",
    NotificationCron: "",
    KeyVaultURL: "",
    RSAKey: "RSAKey",
    AppSecret: "AppSecret",
    TenantId: "",
    AppRegOrgTenantId: "",
    ContractLibraryId: "",
    RequestLibraryId: "",
    OutlookClientId: "",
    OutlookClientSecret: "",
    TenantName: "",
    AIBuildId: "",
    CompanyName: "",
    MaxEntityChar: 100000,
  });
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [SingleOrgAppRegEnabled, setSingleOrgAppRegEnabled] = useState(false);
  const [OutlookAddInEnabled, setOutlookAddInEnabled] = useState(false);
  const [AIEnabled, setAIEnabled] = useState(false);

  const navigate = useNavigate();
  const isSmall = useBreakpointValue({ base: true, md: false });

  //⛔ Validate Required fields To enable submit button
  const isError = !values.RedisBuild || !values.BuildId || !values.Origin.trim().length || !values.BaseUrl.trim().length ||
    !values.SiteUrl.trim().length || !values.ClientId.trim().length || !values.KeyVaultURL.trim().length || !values.RSAKey.trim().length ||
    !values.AppSecret.trim().length || !values.TenantId.trim().length || (SingleOrgAppRegEnabled && !values.AppRegOrgTenantId.trim().length) ||
    !values.ContractLibraryId.trim().length || !values.RequestLibraryId.trim().length || (OutlookAddInEnabled && !values.TenantName.trim().length) ||
    (AIEnabled && !values.AIBuildId.trim().length) || (AIEnabled && !values.CompanyName.trim().length) || (AIEnabled && !values.MaxEntityChar);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  }, []);


  const onSingleOrgAppRegChange = useCallback(() => {
    setSingleOrgAppRegEnabled(!SingleOrgAppRegEnabled);
    if (!SingleOrgAppRegEnabled === false) {
      setValues((prevState) => ({
        ...prevState,
        AppRegOrgTenantId: "",
      }));
    }
  }, [SingleOrgAppRegEnabled]);

  const onOutlookAddInChange = useCallback(() => {
    setOutlookAddInEnabled(!OutlookAddInEnabled);
    if (!OutlookAddInEnabled === false) {
      setValues((prevState) => ({
        ...prevState,
        OutlookClientId: "",
        OutlookClientSecret: "",
        TenantName: "",
      }));
    }
  }, [OutlookAddInEnabled]);

  const onAIEnabledChange = useCallback(() => {
    setAIEnabled(!AIEnabled);
    if (!AIEnabled === false) {
      setValues((prevState) => ({
        ...prevState,
        AIBuildId: "",
        CompanyName: "",
        MaxEntityChar: 1000000,
      }));
    }
  }, [AIEnabled]);

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data = backendServiceTemplateV4_3;
    const placeHolders = [...data.match(regEx) as RegExpMatchArray].filter(onlyUnique);
    const _values: Record<string, any> = {
      ...values,
      AiHostName: AIEnabled ? "http://ai" : "",
      AiPort: AIEnabled ? "5000" : "",
      AiEnabled: AIEnabled ? "true" : "",
      ClauseModelPath: AIEnabled ? "//model-clause-v1//model-best" : "",
      EntityModelPath: AIEnabled ? "//model-entity-v1//model-best" : "",
      CompanyName: AIEnabled ? values.CompanyName : "",
      MaxEntityChar: AIEnabled ? values.MaxEntityChar : "",
      AppRegOrgTenantId: SingleOrgAppRegEnabled ? values.AppRegOrgTenantId : "common",
      AiImageConfig: AIEnabled ? `
  ai:
    image: dock365usa/ai-engine:${values.AIBuildId}
    restart: unless-stopped
    volumes:
      - app:/home/python/app 
    ports:
      - "5000:5000"
    networks:
      - app-network` : "",
    };
    for (const field of placeHolders) {
      if (_values[field]) {
        data = data.replace(getReplacerRegex(field), _values[field]);
      } else {
        data = data.replace(getReplacerRegex(field), "");
      }
    }
    setLoading(false);
    setResult(data);
    setResultVisible(true);
  }, [values, AIEnabled, SingleOrgAppRegEnabled]);

  if (resultVisible) {
    return (
      <OutputPage
        title="Backend Service Config - V4.3.x"
        content={result}
        fileName="Dock-Contract-BackendService-Config.yaml"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Backend Service Config - V4.3.x" />

      <form onSubmit={onSubmit} >
        <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.RedisBuild}>
              <FormLabel htmlFor="RedisBuild">Redis Build ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="RedisBuild"
                  name="RedisBuild"
                  type="number"
                  value={values.RedisBuild}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.RedisBuild && <FormErrorMessage>Redis Build ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.BuildId}>
              <FormLabel htmlFor="BuildId">Backend Build ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="BuildId"
                  name="BuildId"
                  type="number"
                  value={values.BuildId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.BuildId && <FormErrorMessage>Backend Build ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.Origin.trim().length}>
              <FormLabel htmlFor="Origin">Origin Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="Origin"
                  name="Origin"
                  value={values.Origin}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.Origin.trim().length && <FormErrorMessage>Origin Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.BaseUrl.trim().length}>
              <FormLabel htmlFor="BaseUrl">Base Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="BaseUrl"
                  name="BaseUrl"
                  value={values.BaseUrl}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.BaseUrl.trim().length && <FormErrorMessage>Base Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.SiteUrl.trim().length}>
              <FormLabel htmlFor="SiteUrl">Site Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="SiteUrl"
                  name="SiteUrl"
                  value={values.SiteUrl}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.SiteUrl.trim().length && <FormErrorMessage>Site Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.ClientId.trim().length}>
              <FormLabel htmlFor="ClientId">Client ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="ClientId"
                  name="ClientId"
                  value={values.ClientId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ClientId.trim().length && <FormErrorMessage>Client ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl>
              <FormLabel htmlFor="NotificationCron">Notification Cron Expression</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="NotificationCron"
                  name="NotificationCron"
                  value={values.NotificationCron}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.KeyVaultURL.trim().length}>
              <FormLabel htmlFor="KeyVaultURL">Key Vault Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="KeyVaultURL"
                  name="KeyVaultURL"
                  value={values.KeyVaultURL}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.KeyVaultURL.trim().length && <FormErrorMessage>Key Vault Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.RSAKey.trim().length}>
              <FormLabel htmlFor="RSAKey">Key vault Key Name</FormLabel>
              <Tooltip label='The name of key added in key vault' placement='top-end'>
                <Input
                  id="RSAKey"
                  name="RSAKey"
                  value={values.RSAKey}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.RSAKey.trim().length && <FormErrorMessage>Key vault Key Name is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.AppSecret.trim().length}>
              <FormLabel htmlFor="AppSecret">Key Vault App Secret name</FormLabel>
              <Tooltip label='The name of app secret added in key vault' placement='top-end'>
                <Input
                  id="AppSecret"
                  name="AppSecret"
                  value={values.AppSecret}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.AppSecret.trim().length && <FormErrorMessage>Key Vault App Secret name is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.TenantId.trim().length}>
              <FormLabel htmlFor="TenantId">Tenant Id</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="TenantId"
                  name="TenantId"
                  value={values.TenantId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.TenantId.trim().length && <FormErrorMessage>Tenant Id is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.ContractLibraryId.trim().length}>
              <FormLabel htmlFor="ContractLibraryId">Contract Library ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="ContractLibraryId"
                  name="ContractLibraryId"
                  value={values.ContractLibraryId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ContractLibraryId.trim().length && <FormErrorMessage>Contract Library ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.RequestLibraryId.trim().length}>
              <FormLabel htmlFor="RequestLibraryId">Request Library ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="RequestLibraryId"
                  name="RequestLibraryId"
                  value={values.RequestLibraryId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.RequestLibraryId.trim().length && <FormErrorMessage>Request Library ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

        </Grid>

        {/*⛔ Single Organization App Registration Fields */}
        <FormControl display='flex' alignItems='center' mt={10} py={2} gap="2">
          <Switch id='SingleOrgAppRegEnabled' name="SingleOrgAppRegEnabled" onChange={onSingleOrgAppRegChange} />
          <FormLabel htmlFor='SingleOrgAppRegEnabled' mb='0'>
            Single Organization App Registration
          </FormLabel>
        </FormControl>
        {SingleOrgAppRegEnabled &&
          <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.AppRegOrgTenantId.trim().length}>
                <FormLabel htmlFor="AppRegOrgTenantId">Organization Tenant Id</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="AppRegOrgTenantId"
                    name="AppRegOrgTenantId"
                    value={values.AppRegOrgTenantId}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.AppRegOrgTenantId.trim().length && <FormErrorMessage>Organization Tenant Id is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

          </Grid>
        }

        {/*⛔ Outlook Addin Fields */}
        <FormControl display='flex' alignItems='center' mt={10} py={2} gap="2">
          <Switch id='OutlookAddInEnabled' name="OutlookAddInEnabled" onChange={onOutlookAddInChange} />
          <FormLabel htmlFor='OutlookAddInEnabled' mb='0'>
            Enable Outlook Addin
          </FormLabel>
        </FormControl>
        {OutlookAddInEnabled &&
          <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
            <GridItem h='10' my={4}>
              <FormControl>
                <FormLabel htmlFor="OutlookClientId">Outlook Client ID</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="OutlookClientId"
                    name="OutlookClientId"
                    value={values.OutlookClientId}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl>
                <FormLabel htmlFor="OutlookClientSecret">Outlook Client Secret</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="OutlookClientSecret"
                    name="OutlookClientSecret"
                    value={values.OutlookClientSecret}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.TenantName.trim().length}>
                <FormLabel htmlFor="TenantName">Tenant Name</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="TenantName"
                    name="TenantName"
                    value={values.TenantName}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.TenantName.trim().length && <FormErrorMessage>Tenant Name is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>
          </Grid>
        }

        {/*⛔ AI Fields */}
        <FormControl display='flex' alignItems='center' mt={10} py={2} gap="2">
          <Switch id='AIEnabled' name="AIEnabled" onChange={onAIEnabledChange} />
          <FormLabel htmlFor='AIEnabled' mb='0'>
            Enable AI
          </FormLabel>
        </FormControl>
        {AIEnabled &&
          <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.AIBuildId.trim().length}>
                <FormLabel htmlFor="AIBuildId">AI image build ID</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="AIBuildId"
                    name="AIBuildId"
                    value={values.AIBuildId}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.AIBuildId.trim().length && <FormErrorMessage>AI image build ID is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.CompanyName.trim().length}>
                <FormLabel htmlFor="CompanyName">Company Name</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="CompanyName"
                    name="CompanyName"
                    value={values.CompanyName}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                    placeholder="Company Name|Company Short Name|Company Domain"
                  />
                </Tooltip>
                {!values.CompanyName.trim().length && <FormErrorMessage>Company Name is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.MaxEntityChar}>
                <FormLabel htmlFor="MaxEntityChar">Maximum Entity Character Value</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="MaxEntityChar"
                    name="MaxEntityChar"
                    type="number"
                    value={values.MaxEntityChar}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.MaxEntityChar && <FormErrorMessage>Maximum Entity Character Value is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

          </Grid>
        }


        {/*⛔ Footer Buttons */}
        <Box>
          <Flex justifyContent={isSmall ? "center" : "flex-end"} gap={5} my={10}>
            <Button
              mt={4}
              variant="outline"
              colorScheme='facebook'
              width={isSmall ? "45%" : "32"}
              disabled={loading}
              onClick={() => { navigate("/"); }}>
              Back
            </Button>
            <Button size='md'
              colorScheme='facebook'
              type="submit" mt={4}
              width={isSmall ? "45%" : "32"}
              isDisabled={isError}>
              Create
            </Button>
          </Flex>
        </Box>
      </form>
    </FormContainer>
  );
};

export default Version4_3_xForm;
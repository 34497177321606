import { Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AppContext } from "../../AppProvider";

const FormGrid: React.FC<IProps> = ({ children }) => {
  const { isSmall } = useContext(AppContext);
  return (
    <Grid
      templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(1, 1fr)"}
      gap={6}
      py={3}
      minW={isSmall ? "90vw" : "50vw"}
    >
      {children}
    </Grid>
  );
};

export default FormGrid;

interface IProps {
  children: React.ReactNode;
}
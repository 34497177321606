import React, { useCallback } from "react";
import { Button, Tooltip, useToast } from "@chakra-ui/react";
import { AiOutlineClear } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const FloatingButton: React.FC<IProps> = ({ variableName }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const onclear = useCallback(() => {
    localStorage.removeItem(variableName);
    toast({ title: "Memory cleared", status: "success", isClosable: true });
    navigate("/");
  }, [variableName]);

  return (
    <Tooltip
      label="Clear memory"
      fontSize="1rem"
      placement="top-start"
    >
      <Button
        position="fixed"
        bottom={4}
        right={4}
        borderRadius="full"
        width="60px"
        height="60px"
        boxShadow="lg"
        backgroundColor="red.500"
        color="white"
        _hover={{ backgroundColor: "red.600" }}
        _active={{ backgroundColor: "red.700" }}
        onClick={onclear}
      >
        <AiOutlineClear fontSize="30px" />
      </Button>
    </Tooltip>
  );
};

export default FloatingButton;

interface IProps {
  variableName: string;
}
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  FormControl, FormLabel, Input, FormErrorMessage, InputRightElement, IconButton, InputGroup, Tooltip, Flex, Icon, HStack, Radio, RadioGroup, Switch,
} from "@chakra-ui/react";
import * as Yup from "yup";
import FormHeader from "../../../components/form/FormHeader";
import FormContainer from "../../../components/form/FormContainer";
import FormGrid from "../../../components/form/FormGrid";
import FormFooter from "../../../components/form/FormFooter";
import { generateUniqueGUID } from "../../../helpers/generateUniqueGuid";
import { InfoOutlineIcon, RepeatIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import FloatingButton from "../../../components/FloatingResetButton";
import { getReplacerRegex, onlyUnique, regEx } from "../../../helpers/replacerRegex";
import { backendServiceTemplateV4_5 } from "../../../const/templates/backendServiceTemplate";
import OutputPage from "../../../components/OutputPage";

const Version4_5_xForm: React.FC = () => {
  const [showLogPassword, setshowLogPassword] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [resultVisible, setResultVisible] = useState<boolean>(false);

  const onSubmitForm = useCallback((e: any) => {
    let data = backendServiceTemplateV4_5;
    const placeHolders = [...(data.match(regEx) as RegExpMatchArray)].filter(
      onlyUnique
    );
    const _values: Record<string, any> = {
      ...e,
      AiHostName: e?.AiEnabled ? e?.AiHostName : "",
      AiPort: e?.AiEnabled ? "443" : "",
      AiEnabled: e?.AiEnabled ? "true" : "",
      ClauseModelPath: e?.AiEnabled ? "//model-clause-v1//model-best" : "",
      EntityModelPath: e?.AiEnabled ? "//model-entity-v1//model-best" : "",
      CompanyName: e?.AiEnabled ? e?.CompanyName : "",
      MaxEntityChar: e?.AiEnabled ? e?.MaxEntityChar : "",
      SingleOrgAppRegEnabled: e?.SingleOrgAppRegEnabled ? "true" : "false",
      AiImageConfig: e?.AiEnabled
        ? `
ai:
  image: dock365usa/ai-engine:${e.AIBuildId}
  restart: unless-stopped
  volumes:
    - app:/home/python/app 
  ports:
    - "5000:5000"
  networks:
    - app-network`
        : "",
    };
    for (const field of placeHolders) {
      if (_values[field]) {
        data = data.replace(getReplacerRegex(field), _values[field]);
      } else {
        data = data.replace(getReplacerRegex(field), "");
      }
    }
    setResult(data);
    setResultVisible(true);
    localStorage.setItem("Version4_5_xFormValues", JSON.stringify(e));
  }, []);

  const {
    errors, touched, values, handleChange, handleBlur, handleSubmit, setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("Version4_5_xFormValues")!);
    if (data) {
      for (const key of Object.keys(data)) {
        setFieldValue(key, data[key]);
      }
    }
  }, []);

  if (resultVisible) {
    console.log("rrrrrrrrr", result);
    return (
      <OutputPage
        title="Backend Service Config - V4.5.x"
        content={result}
        fileName="Dock-Contract-BackendService-Config.yaml"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Backend Service Config - V4.5.x" />
      <form onSubmit={handleSubmit} onChange={handleChange} onBlur={handleBlur}>
        <FormGrid>
          {/* Redis Build ID */}
          <FormControl isInvalid={errors.RedisBuild && touched.RedisBuild || false}>
            <FormLabel htmlFor="RedisBuild">Redis Build ID</FormLabel>
            <Input
              id="RedisBuild"
              name="RedisBuild"
              type="number"
              defaultValue={values.RedisBuild}
              size="sm"
              autoComplete="off"
            />
            {errors.RedisBuild && <FormErrorMessage>{errors.RedisBuild}</FormErrorMessage>}
          </FormControl>

          {/* Backend Build ID */}
          <FormControl isInvalid={errors.BuildId && touched.BuildId || false}>
            <FormLabel htmlFor="BuildId">Backend Build ID</FormLabel>
            <Input
              id="BuildId"
              name="BuildId"
              type="number"
              defaultValue={values.BuildId}
              size="sm"
              autoComplete="off"
              placeholder="1234"
            />
            {errors.BuildId && <FormErrorMessage>{errors.BuildId}</FormErrorMessage>}
          </FormControl>

          {/* Base Url */}
          <FormControl isInvalid={errors.BaseUrl && touched.BaseUrl || false}>
            <FormLabel htmlFor="BaseUrl">Base Url</FormLabel>
            <Input
              id="BaseUrl"
              name="BaseUrl"
              defaultValue={values.BaseUrl}
              size="sm"
              autoComplete="off"
              placeholder="https://dockcms-mock.azurewebsites.net"
            />
            {errors.BaseUrl && <FormErrorMessage>{errors.BaseUrl}</FormErrorMessage>}
          </FormControl>

          {/* Origin Url */}
          <FormControl isInvalid={errors.Origin && touched.Origin || false}>
            <FormLabel htmlFor="Origin">Origin Url</FormLabel>
            <Input
              id="Origin"
              name="Origin"
              defaultValue={values.Origin}
              size="sm"
              autoComplete="off"
              placeholder="https://mydockdev.sharepoint.com"
            />
            {errors.Origin && <FormErrorMessage>{errors.Origin}</FormErrorMessage>}
          </FormControl>

          {/* Session Secret */}
          <FormControl isInvalid={errors.SessionSecret && touched.SessionSecret || false}>
            <Flex align="center">
              <FormLabel htmlFor="SessionSecret">Session Secret</FormLabel>
              <Tooltip
                label="Each client is provided with a distinct Session Secret and it is not necessary to regenerate it during the upgrade process."
                placement="top-start"
              >
                <Icon as={InfoOutlineIcon} color="gray.400" mb={2} />
              </Tooltip>
            </Flex>
            {errors.SessionSecret && <FormErrorMessage>{errors.SessionSecret}</FormErrorMessage>}
            <InputGroup size="md">
              <Input
                id="SessionSecret"
                name="SessionSecret"
                defaultValue={values.SessionSecret}
                size="sm"
                autoComplete="off"
                value={values.SessionSecret}
                onChange={(e) => setFieldValue("SessionSecret", e.target.value)}
                placeholder="Generate Session Secret"
              />
              <InputRightElement pb={2}>
                <IconButton
                  colorScheme="blue"
                  size="xs"
                  aria-label="Generate"
                  variant="outline"
                  fontWeight="bold"
                  fontSize="md"
                  onClick={() => { setFieldValue("SessionSecret", generateUniqueGUID().replace(/-/g, "")); }}
                  icon={<RepeatIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* Authentication Method */}
          <FormControl isInvalid={errors.AuthenticationMethod && touched.AuthenticationMethod || false}>
            <FormLabel htmlFor="AuthenticationMethod">Authentication Method</FormLabel>
            <RadioGroup
              id="AuthenticationMethod"
              name="AuthenticationMethod"
              onChange={(method) => setFieldValue("AuthenticationMethod", method)}
              value={values.AuthenticationMethod}
            >
              <HStack spacing={4}>
                <Radio value="JWT">JWT</Radio>
                <Radio value="SESSION">SESSION</Radio>
              </HStack>
            </RadioGroup>
            {errors.AuthenticationMethod && <FormErrorMessage>{errors.AuthenticationMethod}</FormErrorMessage>}
          </FormControl>

          {/* Notification Cron Expression */}
          <FormControl isInvalid={errors.NotificationCron && touched.NotificationCron || false}>
            <FormLabel htmlFor="NotificationCron">Notification Cron Expression</FormLabel>
            <Input
              id="NotificationCron"
              name="NotificationCron"
              defaultValue={values.NotificationCron}
              size="sm"
              autoComplete="off"
            />
            {errors.NotificationCron && <FormErrorMessage>{errors.NotificationCron}</FormErrorMessage>}
          </FormControl>

          {/* Dock App Secret */}
          <FormControl isInvalid={errors.DockAppSecret && touched.DockAppSecret || false}>
            <FormLabel htmlFor="DockAppSecret">Dock App Secret</FormLabel>
            <Input
              id="DockAppSecret"
              name="DockAppSecret"
              defaultValue={values.DockAppSecret}
              size="sm"
              autoComplete="off"
            />
            {errors.DockAppSecret && <FormErrorMessage>{errors.DockAppSecret}</FormErrorMessage>}
          </FormControl>
        </FormGrid>

        <FormGrid>
          {/* Log Token */}
          <FormControl isInvalid={errors.LogToken && touched.LogToken || false}>
            <FormLabel htmlFor="LogToken">Log Token</FormLabel>
            <Input
              id="LogToken"
              name="LogToken"
              defaultValue={values.LogToken}
              size="sm"
              autoComplete="off"
            />
            {errors.LogToken && <FormErrorMessage>{errors.LogToken}</FormErrorMessage>}
          </FormControl>

          {/* Log Url */}
          <FormControl isInvalid={errors.LogPath && touched.LogPath || false}>
            <FormLabel htmlFor="LogPath">Log Path</FormLabel>
            <Input
              id="LogPath"
              name="LogPath"
              defaultValue={values.LogPath}
              size="sm"
              autoComplete="off"
            />
            {errors.LogPath && <FormErrorMessage>{errors.LogPath}</FormErrorMessage>}
          </FormControl>

          {/* Log Level */}
          <FormControl isInvalid={errors.LogLevel && touched.LogLevel || false}>
            <FormLabel htmlFor="LogLevel">Log Level</FormLabel>
            <Input
              id="LogLevel"
              name="LogLevel"
              defaultValue={values.LogLevel}
              size="sm"
              autoComplete="off"
            />
            {errors.LogLevel && <FormErrorMessage>{errors.LogLevel}</FormErrorMessage>}
          </FormControl>

          {/* Log Email */}
          <FormControl isInvalid={errors.LogEmail && touched.LogEmail || false}>
            <FormLabel htmlFor="LogEmail">Log Email</FormLabel>
            <Input
              id="LogEmail"
              name="LogEmail"
              defaultValue={values.LogEmail}
              size="sm"
              autoComplete="off"
            />
            {errors.LogEmail && <FormErrorMessage>{errors.LogEmail}</FormErrorMessage>}
          </FormControl>

          {/* Log Password */}
          <FormControl isInvalid={errors.LogPassword && touched.LogPassword || false}>
            <FormLabel htmlFor="LogPassword">Log Password</FormLabel>
            <InputGroup>
              <Input
                id="LogPassword"
                name="LogPassword"
                defaultValue={values.LogPassword}
                type={showLogPassword ? "text" : "password"}
                size="sm"
                autoComplete="off"
              />
              <InputRightElement>
                <IconButton
                  size="lg"
                  mb={1.5}
                  variant="link"
                  icon={showLogPassword ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={() => setshowLogPassword(!showLogPassword)}
                  aria-label="LogPassword"
                />
              </InputRightElement>
            </InputGroup>
            {errors.LogPassword && <FormErrorMessage>{errors.LogPassword}</FormErrorMessage>}
          </FormControl>
        </FormGrid>

        <FormGrid>
          {/* Mongo Uri */}
          <FormControl isInvalid={errors.MongoUri && touched.MongoUri || false}>
            <FormLabel htmlFor="MongoUri">Mongo Uri</FormLabel>
            <Input
              id="MongoUri"
              name="MongoUri"
              defaultValue={values.MongoUri}
              size="sm"
              autoComplete="off"
              placeholder="mongodb://mysite:xxxxx@mysite:12345/mysite"
            />
            {errors.MongoUri && <FormErrorMessage>{errors.MongoUri}</FormErrorMessage>}
          </FormControl>

          {/* Tenant Id */}
          <FormControl isInvalid={errors.Domain && touched.Domain || false}>
            <FormLabel htmlFor="Domain">Domain</FormLabel>
            <Input
              id="Domain"
              name="Domain"
              defaultValue={values.Domain}
              size="sm"
              autoComplete="off"
              placeholder="mysite.sharepoint.com"
            />
            {errors.Domain && <FormErrorMessage>{errors.Domain}</FormErrorMessage>}
          </FormControl>

          {/* Tenant Id */}
          <FormControl isInvalid={errors.TenantId && touched.TenantId || false}>
            <FormLabel htmlFor="TenantId">Tenant Id</FormLabel>
            <Input
              id="TenantId"
              name="TenantId"
              defaultValue={values.TenantId}
              size="sm"
              autoComplete="off"
            />
            {errors.TenantId && <FormErrorMessage>{errors.TenantId}</FormErrorMessage>}
          </FormControl>

          {/* Site Url */}
          <FormControl isInvalid={errors.SiteUrl && touched.SiteUrl || false}>
            <FormLabel htmlFor="SiteUrl">Site Url</FormLabel>
            <Input
              id="SiteUrl"
              name="SiteUrl"
              defaultValue={values.SiteUrl}
              size="sm"
              autoComplete="off"
              placeholder="https://mysite.sharepoint.com/sites/ContractManagement"
            />
            {errors.SiteUrl && <FormErrorMessage>{errors.SiteUrl}</FormErrorMessage>}
          </FormControl>

          {/* Client Id */}
          <FormControl isInvalid={errors.ClientId && touched.ClientId || false}>
            <FormLabel htmlFor="ClientId">Client Id</FormLabel>
            <Input
              id="ClientId"
              name="ClientId"
              defaultValue={values.ClientId}
              size="sm"
              autoComplete="off"
            />
            {errors.ClientId && <FormErrorMessage>{errors.ClientId}</FormErrorMessage>}
          </FormControl>

          {/* Single Organization App Registration */}
          <FormControl isInvalid={errors.SingleOrgAppRegEnabled && touched.SingleOrgAppRegEnabled || false}>
            <FormLabel htmlFor="SingleOrgAppRegEnabled">Single Organization App Registration</FormLabel>
            <Switch
              id='SingleOrgAppRegEnabled'
              name="SingleOrgAppRegEnabled"
              isChecked={values.SingleOrgAppRegEnabled}
            />
          </FormControl>

          {/* Sharepoint Authentication method */}
          <FormControl isInvalid={errors.UseModernAuth && touched.UseModernAuth || false}>
            <FormLabel htmlFor="UseModernAuth">Sharepoint Authentication method</FormLabel>
            <Switch
              id='UseModernAuth'
              name="UseModernAuth"
              isChecked={values.UseModernAuth}
            />
          </FormControl>
        </FormGrid>

        {values.UseModernAuth && (
          <FormGrid>
            { /* 2️⃣0️⃣ Certificate Name */}
            <FormControl isInvalid={errors.CertificateName && touched.CertificateName || false}>
              <FormLabel htmlFor="CertificateName">Certificate Name</FormLabel>
              <Input
                id="CertificateName"
                name="CertificateName"
                defaultValue={values.CertificateName}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.CertificateName && <FormErrorMessage>{errors.CertificateName}</FormErrorMessage>}
            </FormControl>

            { /* 2️⃣1️⃣ Thumb Print */}
            <FormControl isInvalid={errors.ThumbPrint && touched.ThumbPrint || false}>
              <FormLabel htmlFor="ThumbPrint">Thumb Print</FormLabel>
              <Input
                id="ThumbPrint"
                name="ThumbPrint"
                defaultValue={values.ThumbPrint}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.ThumbPrint && <FormErrorMessage>{errors.ThumbPrint}</FormErrorMessage>}
            </FormControl>

            { /* 2️⃣2️⃣ Authority */}
            <FormControl isInvalid={errors.Authority && touched.Authority || false}>
              <FormLabel htmlFor="Authority">Authority</FormLabel>
              <Input
                id="Authority"
                name="Authority"
                defaultValue={values.Authority}
                size="sm"
                autoComplete="off"
                required
                placeholder="https://login.microsoftonline.com/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              />
              {errors.Authority && <FormErrorMessage>{errors.Authority}</FormErrorMessage>}
            </FormControl>
          </FormGrid>
        )}

        <FormGrid>
          {/* Key Vault Url */}
          <FormControl isInvalid={errors.KeyVaultURL && touched.KeyVaultURL || false}>
            <FormLabel htmlFor="KeyVaultUrl">Key Vault Url</FormLabel>
            <Input
              id="KeyVaultURL"
              name="KeyVaultURL"
              defaultValue={values.KeyVaultURL}
              size="sm"
              autoComplete="off"
              placeholder="https://mysite.vault.azure.net"
            />
            {errors.KeyVaultURL && <FormErrorMessage>{errors.KeyVaultURL}</FormErrorMessage>}
          </FormControl>

          {/* Key Vault Key Name */}
          <FormControl isInvalid={errors.RSAKey && touched.RSAKey || false}>
            <FormLabel htmlFor="RSAKey">Key Vault Key Name</FormLabel>
            <Input
              id="RSAKey"
              name="RSAKey"
              defaultValue={values.RSAKey}
              size="sm"
              autoComplete="off"
            />
            {errors.RSAKey && <FormErrorMessage>{errors.RSAKey}</FormErrorMessage>}
          </FormControl>

          {/* Key Vault App Secret Name */}
          <FormControl isInvalid={errors.AppSecret && touched.AppSecret || false}>
            <FormLabel htmlFor="AppSecret">Key Vault App Secret Name</FormLabel>
            <Input
              id="AppSecret"
              name="AppSecret"
              defaultValue={values.AppSecret}
              size="sm"
              autoComplete="off"
            />
            {errors.AppSecret && <FormErrorMessage>{errors.AppSecret}</FormErrorMessage>}
          </FormControl>
        </FormGrid>

        <FormGrid>
          {/* Enable AI */}
          <FormControl isInvalid={errors.AiEnabled && touched.AiEnabled || false}>
            <FormLabel htmlFor="AiEnabled">Enable AI</FormLabel>
            <Switch
              id='AiEnabled'
              name="AiEnabled"
              isChecked={values.AiEnabled}
            />
          </FormControl>

          {values.AiEnabled && (
            <>
              {/* AI Image Build ID */}
              <FormControl isInvalid={errors.AIBuildId && touched.AIBuildId || false}>
                <FormLabel htmlFor="AIBuildId">AI Image Build ID</FormLabel>
                <Input
                  id="AIBuildId"
                  name="AIBuildId"
                  type="number"
                  defaultValue={values.AIBuildId}
                  size="sm"
                  autoComplete="off"
                  placeholder="123"
                  required
                />
                {errors.AIBuildId && <FormErrorMessage>{errors.AIBuildId}</FormErrorMessage>}
              </FormControl>

              {/* AI Hostname */}
              <FormControl isInvalid={errors.AiHostName && touched.AiHostName || false}>
                <FormLabel htmlFor="AiHostName">AI Hostname</FormLabel>
                <Input
                  id="AiHostName"
                  name="AiHostName"
                  defaultValue={values.AiHostName}
                  size="sm"
                  autoComplete="off"
                  placeholder="https://mysite.azurewebsites.net"
                  required
                />
                {errors.AiHostName && <FormErrorMessage>{errors.AiHostName}</FormErrorMessage>}
              </FormControl>

              {/* Company Name */}
              <FormControl isInvalid={errors.CompanyName && touched.CompanyName || false}>
                <FormLabel htmlFor="CompanyName">Company Name</FormLabel>
                <Input
                  id="CompanyName"
                  name="CompanyName"
                  defaultValue={values.CompanyName}
                  size="sm"
                  autoComplete="off"
                  placeholder="Company Name|Company Short Name|Company Domain"
                  required
                />
                {errors.CompanyName && <FormErrorMessage>{errors.CompanyName}</FormErrorMessage>}
              </FormControl>

              {/* Maximum Entity Character Value */}
              <FormControl isInvalid={errors.MaxEntityChar && touched.MaxEntityChar || false}>
                <FormLabel htmlFor="MaxEntityChar">Maximum Entity Character Value</FormLabel>
                <Input
                  id="MaxEntityChar"
                  name="MaxEntityChar"
                  defaultValue={values.MaxEntityChar}
                  size="sm"
                  autoComplete="off"
                  required
                />
                {errors.MaxEntityChar && <FormErrorMessage>{errors.MaxEntityChar}</FormErrorMessage>}
              </FormControl>
            </>
          )}

          {/* Dotnet Host Name */}
          <FormControl isInvalid={errors.DotnetHostName && touched.DotnetHostName || false}>
            <FormLabel htmlFor="DotnetHostName">Dotnet Host Name</FormLabel>
            <Input
              id="DotnetHostName"
              name="DotnetHostName"
              defaultValue={values.DotnetHostName}
              size="sm"
              autoComplete="off"
              placeholder="https://mysite-dotnet.azurewebsites.net"
            />
            {errors.DotnetHostName && <FormErrorMessage>{errors.DotnetHostName}</FormErrorMessage>}
          </FormControl>
        </FormGrid>

        <FormFooter />
      </form>
      <FloatingButton variableName="Version4_5_xFormValues" />
    </FormContainer >
  );
};

export default Version4_5_xForm;

const initialValues = {
  RedisBuild: 356,
  BuildId: undefined,
  BaseUrl: "",
  Origin: "",
  SessionSecret: "",
  AuthenticationMethod: "JWT",
  NotificationCron: "30 0  * * *",
  DockAppSecret: "",
  LogToken: "",
  LogPath: "/home/logs",
  LogLevel: "debug",
  LogEmail: "support@mydock365.com",
  LogPassword: "",
  MongoUri: "",
  Domain: "",
  TenantId: "",
  SiteUrl: "",
  ClientId: "",
  SingleOrgAppRegEnabled: false,
  UseModernAuth: false,
  CertificateName: "",
  ThumbPrint: "",
  Authority: "",
  KeyVaultURL: "",
  RSAKey: "RSAKey",
  AppSecret: "AppSecret",
  AiEnabled: false,
  AIBuildId: undefined,
  AiHostName: "",
  CompanyName: "",
  MaxEntityChar: 100000,
  DotnetHostName: "",
};

const validationSchema = Yup.object().shape({
  RedisBuild: Yup.number().required("Required"),
  BuildId: Yup.number().required("Required"),
  BaseUrl: Yup.string().url("Enter a valid URL").required("Required"),
  Origin: Yup.string().url("Enter a valid URL").required("Required"),
  SessionSecret: Yup.string().length(32, "Must be 32 characters"),
  AuthenticationMethod: Yup.string().required("Required"),
  NotificationCron: Yup.string().test("NotificationCron", "Invalid cron expression", (value) => {
    if (!value) {
      return true;
    }
    return cronExpressionRegex.test(value);
  }).required("Required"),
  DockAppSecret: Yup.string().required("Required"),
  LogToken: Yup.string().required("Required"),
  LogPath: Yup.string().required("Required"),
  LogLevel: Yup.string().required("Required"),
  LogEmail: Yup.string().email("Enter a valid email"),
  LogPassword: Yup.string().required("Required"),
  MongoUri: Yup.string().required("Required"),
  Domain: Yup.string().required("Required").matches(/^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/, "Not a valid domain"),
  TenantId: Yup.string().required("Required"),
  SiteUrl: Yup.string().url("Enter a valid URL").required("Required"),
  ClientId: Yup.string().required("Required"),
  SingleOrgAppRegEnabled: Yup.boolean(),
  UseModernAuth: Yup.boolean(),
  CertificateName: Yup.string(),
  ThumbPrint: Yup.string(),
  Authority: Yup.string().url("Enter a valid URL"),
  KeyVaultURL: Yup.string().url("Enter a valid URL").required("Required"),
  RSAKey: Yup.string().required("Required"),
  AppSecret: Yup.string().required("Required"),
  AiEnabled: Yup.boolean(),
  AIBuildId: Yup.number(),
  AiHostName: Yup.string().url("Enter a valid URL"),
  CompanyName: Yup.string(),
  MaxEntityChar: Yup.number(),
  DotnetHostName: Yup.string().url("Enter a valid URL").required("Required"),
});


const cronExpressionRegex = /^(\*|\d{1,2}|\d{1,2}(-\d{1,2})?)(\/\d{1,2})?(\s+(\*|\d{1,2}|\d{1,2}(-\d{1,2})?)(\/\d{1,2})?){4}$/;


import React, { useContext } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { AppContext } from "../../AppProvider";
import { useNavigate } from "react-router-dom";

const FormFooter: React.FC<IProps> = ({ loading, isError }) => {
  const { isSmall } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        justifyContent={isSmall ? "center" : "flex-end"}
        gap={5}
        mb={20}
      >
        <Button
          mt={4}
          variant="outline"
          colorScheme="facebook"
          width={isSmall ? "45%" : "32"}
          disabled={loading}
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </Button>
        <Button
          size="md"
          colorScheme="facebook"
          type="submit"
          mt={4}
          width={isSmall ? "45%" : "32"}
          isDisabled={isError}
        >
          Create
        </Button>
      </Flex>
    </Box>
  );
};

export default FormFooter;

interface IProps {
  loading?: boolean;
  isError?: boolean;
}
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BackendVersions } from "./const/backendVersions";
import { useBreakpointValue, useColorMode } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDownloadFileName } from "./helpers/getDownloadFileName";

export interface IAppContext {
  selectedVersion: BackendVersions | undefined;
  onVersionChange: ((value: BackendVersions) => void) | undefined;
  isDarkMode: boolean;
  isSmall: boolean;
  defaultDownloadFileName: string;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = React.createContext<IAppContext>({
  selectedVersion: undefined,
  onVersionChange: undefined,
  isDarkMode: false,
  isSmall: false,
  defaultDownloadFileName: "",
  isLoggedIn: false,
  setIsLoggedIn: () => undefined,
});

const AppProvider: React.FC<AppProviderProps> = (props) => {
  const [selectedVersion, setSelectedVersion] = useState<BackendVersions>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { colorMode } = useColorMode();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isSmall: boolean = useBreakpointValue({ base: true, md: false }) || false;
  const isLoggedInDH2 = sessionStorage.getItem("isLoggedInDH2") !== "true";

  useEffect(() => {
    if (isLoggedInDH2) {
      setIsLoggedIn(false);
      navigate("/login");
    } else {
      setIsLoggedIn(true);
      navigate("/");
    }
    const selectedBackendVersion = localStorage.getItem("selectedBackendVersion");
    if (selectedBackendVersion) {
      return setSelectedVersion(selectedBackendVersion as BackendVersions);
    }
    setSelectedVersion(BackendVersions["4.4.x"]);
  }, [isLoggedInDH2]);

  const isDarkMode: boolean = useMemo(() => {
    if (colorMode === "light") {
      return false;
    }
    return true;
  }, [colorMode]);

  const onVersionChange = useCallback((value: BackendVersions) => {
    setSelectedVersion(value);
    localStorage.setItem("selectedBackendVersion", value);
  }, []);

  const defaultDownloadFileName: string = useMemo(() => {
    return getDownloadFileName(pathname);
  }, [pathname]);

  // Prevent right click
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  }, false);

  document.addEventListener("keydown", function (event) {
    // Prevent F12 - Open Developer Tools
    if (event.keyCode === 123) {
      event.preventDefault();
    }

    // Prevent Ctrl+Shift+I - Open Developer Tools
    if (event.ctrlKey && event.shiftKey && (event.keyCode === 73 || event.keyCode === 105)) {
      event.preventDefault();
    }

    // Prevent Ctrl+Shift+J - Open Developer Tools and switch to the Console tab
    if (event.ctrlKey && event.shiftKey && (event.keyCode === 74 || event.keyCode === 106)) {
      event.preventDefault();
    }

    // Prevent Ctrl+Shift+C - Open Developer Tools in inspect element mode
    if (event.ctrlKey && event.shiftKey && (event.keyCode === 67 || event.keyCode === 99)) {
      event.preventDefault();
    }
  }, false);

  return (
    <AppContext.Provider
      value={{
        selectedVersion,
        onVersionChange,
        isDarkMode,
        isSmall,
        defaultDownloadFileName,
        isLoggedIn,
        setIsLoggedIn
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;


interface AppProviderProps {
  children: React.ReactNode;
}
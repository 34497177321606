/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useRef, useState } from "react";
import Zip from "jszip";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon, CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FileZipDownloadIcon } from "./assets/CustomIcons";
import "./ComponentStyles.css";
import { AppContext } from "../AppProvider";

const OutputPage: React.FC<IProps> = (props) => {
  const { isDarkMode, defaultDownloadFileName } = useContext(AppContext);
  const [fileName, setFileName] = useState(defaultDownloadFileName);
  const contentRef = useRef<HTMLPreElement>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const onCopyClick = useCallback(async () => {
    const content = contentRef.current?.innerText;
    await navigator.clipboard.writeText(content as string);
    toast({
      title: "Copied to clipboard!",
      status: "success",
      isClosable: true,
      position: "bottom-right",
    });
  }, [contentRef]);

  const onConfirmDownload = useCallback(async () => {
    const blob = new Blob([props.content], { type: "text/xml" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${fileName}.xml`;
    a.click();
    onClose();
    toast({
      title: "Downloaded successfully!",
      status: "success",
      isClosable: true,
      position: "bottom-right",
    });
    setFileName("file");
  }, [contentRef, fileName]);

  const onDownloadZipClick = useCallback(() => {

    const blob = new Blob([props.content], { type: "text/plain" });
    const zip = new Zip();

    const files: IFileType[] = props.otherFiles
      ? [...props.otherFiles, { name: "manifest.json", data: blob, type: "application/json" }]
      : [{ name: props.fileName, data: blob, type: "text/xml" }];

    for (const item of files) {
      // Zip file with the file name.
      zip.file(item.name, item.data);
    }
    zip.generateAsync({ type: "blob" }).then((content: any) => {
      const element = document.createElement("a");
      element.href = URL.createObjectURL(content);
      element.download = `${props.fileName.slice(
        0,
        props.fileName.lastIndexOf(".")
      )}.zip`;
      element.click();
      element.parentNode?.removeChild(element);
    });
  }, [props.content, props.fileName, props.otherFiles]);

  return (
    <>
      <Box p={3} borderWidth={1} borderRadius={8}>
        <Flex justifyContent="space-between" gap={3} px={10}>
          <Box gap={3} p={2}>
            <Button
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate("/");
              }}
              colorScheme="gray"
              size="sm"
              variant="outline"
            >
              Back
            </Button>
          </Box>
          <Flex justifyContent="space-between" gap={3} p={2}>
            <Button
              rightIcon={<CopyIcon />}
              onClick={onCopyClick}
              colorScheme="facebook"
              size="sm"
              variant="outline"
              _hover={{
                bg: "facebook.400",
                color: "white",
              }}
            >
              Copy
            </Button>
            <Button
              rightIcon={<DownloadIcon />}
              colorScheme="facebook"
              size="sm"
              variant="outline"
              _hover={{
                bg: "facebook.400",
                color: "white",
              }}
              onClick={() => onOpen()}
            >
              Download
            </Button>
            {props.otherFiles && (
              <Button
                onClick={onDownloadZipClick}
                rightIcon={<FileZipDownloadIcon />}
                colorScheme="facebook"
                size="sm"
                variant="outline"
                _hover={{
                  bg: "facebook.400",
                  color: "white",
                }}
              >
                Download as Zip
              </Button>
            )}
          </Flex>
        </Flex>
        <Box
          p={6}
          borderWidth={1}
          borderRadius={8}
          // bg="whitesmoke"
          mx={10}
          overflow="auto"
          maxH="container.sm"
          className="pageContainer"
          bg={isDarkMode ? "gray.900" : "whitesmoke"}
          color={isDarkMode ? "whitesmoke" : "gray.900"}
        >
          <pre
            ref={contentRef}
            className="pageContainer"
            style={{ fontSize: "14px" }}
          >
            {props.content}
          </pre>
        </Box>
      </Box>

      {/* Change file name popup */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change File Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>File Name</FormLabel>
              <Input
                ref={initialRef}
                placeholder="File Name"
                defaultValue={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onConfirmDownload}>
              Download
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OutputPage;

export interface IProps {
  title: string;
  content: string;
  fileName: string;
  otherFiles?: IFileType[];
}

export interface IFileType {
  name: string;
  data: any;
  type?: string;
}


export const wordAddInTemplate = `<?xml version="1.0" encoding="UTF-8"?>
<!--Created:ce44715c-8c4e-446b-879c-ea9ebe0f09c8-->
<OfficeApp
          xmlns="http://schemas.microsoft.com/office/appforoffice/1.1"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns:bt="http://schemas.microsoft.com/office/officeappbasictypes/1.0"
          xmlns:ov="http://schemas.microsoft.com/office/taskpaneappversionoverrides"
          xsi:type="TaskPaneApp">

  <!-- Begin Basic Settings: Add-in metadata, used for all versions of Office unless override provided. -->

  <!-- IMPORTANT! Id must be unique for your add-in, if you reuse 
  this manifest ensure that you change this id to a new GUID. -->
  <Id>0C459895-7DC8-4662-BDA2-F3D2D6EDF45D</Id>

  <!--Version. Updates from the store only get triggered if there is a version change. -->
  <Version>\${Version}</Version>
  <ProviderName>Dock365</ProviderName>
  <DefaultLocale>en-US</DefaultLocale>
  <!-- The display name of your add-in. Used on the store and various 
  places of the Office UI such as the add-ins dialog. -->
  <DisplayName DefaultValue="Contract Add-in" />
  <Description DefaultValue="Dock add-in for contract word documents."/>
  <!-- Icon for your add-in. Used on installation screens and the add-ins dialog. -->
  <IconUrl DefaultValue="https://\${BackendDomain}/images/dockIcon_32x32.png" />

  <SupportUrl DefaultValue="https://www.mydock365.com" />
  <!--End Basic Settings. -->
  <Hosts>
    <Host Name="Document" />
  </Hosts>
  <DefaultSettings>
    <SourceLocation DefaultValue="https://\${BackendDomain}/" />
  </DefaultSettings>
  <!-- End TaskPane Mode integration.  -->

  <Permissions>ReadWriteDocument</Permissions>

  <!-- Begin Add-in Commands Mode integration. -->
  <VersionOverrides xmlns="http://schemas.microsoft.com/office/taskpaneappversionoverrides" 
  xsi:type="VersionOverridesV1_0">

    <!-- The Hosts node is required. -->
    <Hosts>
      <!-- Each host can have a different set of commands. -->
      <!-- Excel host is Workbook, Word host is Document, and PowerPoint host is Presentation. -->
      <!-- Make sure the hosts you override match the hosts declared in the top section of the manifest. -->
      <Host xsi:type="Document">
        <!-- Form factor. Currently only DesktopFormFactor is supported. -->
        <DesktopFormFactor>
          <GetStarted>
            <!-- Title of the Getting Started callout. The resid attribute points to a ShortString resource -->
            <Title resid="Dock.GetStarted.Title"/>

            <!-- Description of the Getting Started callout. resid points to a LongString resource -->
            <Description resid="Dock.GetStarted.Description"/>

            <!-- Points to a URL resource which details how the add-in should be used. -->
            <LearnMoreUrl resid="Dock.GetStarted.LearnMoreUrl"/>
          </GetStarted>

          <!-- PrimaryCommandSurface is the main Office Ribbon. -->

          <ExtensionPoint xsi:type="PrimaryCommandSurface">            
            <CustomTab id="DockTabCustom">
              <Group id="DockCustomTab.ContractGroup">
                <Label resid="Dock.ContractGroupLabel" />
                <Icon>
                  <bt:Image size="16" resid="Dock.tpicon_16x16" />
                  <bt:Image size="32" resid="Dock.tpicon_32x32" />
                  <bt:Image size="80" resid="Dock.tpicon_80x80" />
                </Icon>                
                <Control xsi:type="Button" id="Contoso.TaskpaneButton">
                  <Label resid="Dock.Button.CL.Label" />
                  <Supertip>
                    <Title resid="Dock.Button.CL.Label" />
                    <Description resid="Dock.Button.CL.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Dock.clauseIcon_16x16" />
                    <bt:Image size="32" resid="Dock.clauseIcon_32x32" />
                    <bt:Image size="80" resid="Dock.clauseIcon_80x80" />
                  </Icon>
                  <Action xsi:type="ShowTaskpane">
                    <TaskpaneId>ButtonId1</TaskpaneId>
                    <SourceLocation resid="Dock.Taskpane.CL.Url" />
                  </Action>
                </Control>
                <Control xsi:type="Button" id="Contoso.TaskpaneButton2">
                  <Label resid="Dock.Button.Templates.Label" />
                  <Supertip>
                    <Title resid="Dock.Button.Templates.Label" />
                    <Description resid="Dock.Button.Templates.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Dock.tempIcon_16x16" />
                    <bt:Image size="32" resid="Dock.tempIcon_32x32" />
                    <bt:Image size="80" resid="Dock.tempIcon_80x80" />
                  </Icon>
                  <Action xsi:type="ShowTaskpane">
                    <TaskpaneId>ButtonId2</TaskpaneId>
                    <SourceLocation resid="Dock.Taskpane.Templates.Url" />
                  </Action>
                </Control>                
                <Control xsi:type="Button" id="Contoso.TaskpaneButton3">
                  <Label resid="Dock.Button.eSign.Label" />
                  <Supertip>
                    <Title resid="Dock.Button.eSign.Label" />
                    <Description resid="Dock.Button.eSign.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Dock.eSignIcon_16x16" />
                    <bt:Image size="32" resid="Dock.eSignIcon_32x32" />
                    <bt:Image size="80" resid="Dock.eSignIcon_80x80" />
                  </Icon>
                  <Action xsi:type="ShowTaskpane">
                    <TaskpaneId>ButtonId3</TaskpaneId>
                    <SourceLocation resid="Dock.Taskpane.eSign.Url" />
                  </Action>
                </Control>              
              </Group>
              <Label resid="Dock.CustomTab.Label"/>
            </CustomTab>
          </ExtensionPoint>
        </DesktopFormFactor>
      </Host>
    </Hosts>
    <Resources>
      <bt:Images>        
        <bt:Image id="Dock.tpicon_16x16" DefaultValue="https://\${BackendDomain}/images/dockIcon_16x16.png" />
        <bt:Image id="Dock.tpicon_32x32" DefaultValue="https://\${BackendDomain}/images/dockIcon_32x32.png" />
        <bt:Image id="Dock.tpicon_80x80" DefaultValue="https://\${BackendDomain}/images/dockIcon_80x80.png" />

        <bt:Image id="Dock.clauseIcon_16x16" DefaultValue="https://\${BackendDomain}/images/clauseIcon_16x16.png" />
        <bt:Image id="Dock.clauseIcon_32x32" DefaultValue="https://\${BackendDomain}/images/clauseIcon_32x32.png" />
        <bt:Image id="Dock.clauseIcon_80x80" DefaultValue="https://\${BackendDomain}/images/clauseIcon_80x80.png" />
        
        <bt:Image id="Dock.tempIcon_16x16" DefaultValue="https://\${BackendDomain}/images/templatesIcon_16x16.png" />
        <bt:Image id="Dock.tempIcon_32x32" DefaultValue="https://\${BackendDomain}/images/templatesIcon_32x32.png" />
        <bt:Image id="Dock.tempIcon_80x80" DefaultValue="https://\${BackendDomain}/images/templatesIcon_80x80.png" />
        
        <bt:Image id="Dock.eSignIcon_16x16" DefaultValue="https://\${BackendDomain}/images/eSignIcon_16x16.png" />
        <bt:Image id="Dock.eSignIcon_32x32" DefaultValue="https://\${BackendDomain}/images/eSignIcon_32x32.png" />
        <bt:Image id="Dock.eSignIcon_80x80" DefaultValue="https://\${BackendDomain}/images/eSignIcon_80x80.png" />
      </bt:Images>
      <bt:Urls>
        <bt:Url id="Dock.Taskpane.CL.Url" DefaultValue="https://\${BackendDomain}/word-add-in/clause-library" />
        <bt:Url id="Dock.Taskpane.eSign.Url" DefaultValue="https://\${BackendDomain}/word-add-in/esign" />
        <bt:Url id="Dock.Taskpane.Templates.Url" 
        DefaultValue="https://\${BackendDomain}/word-add-in/contract-templating" />
        <bt:Url id="Dock.GetStarted.LearnMoreUrl" DefaultValue="https://go.microsoft.com/fwlink/?LinkId=276812" />
      </bt:Urls>
      <bt:ShortStrings>
        <bt:String id="Dock.CustomTab.Label" DefaultValue="Dock" />        
        <bt:String id="Dock.ContractGroupLabel" DefaultValue="Contract" />
        <bt:String id="Dock.Button.CL.Label" DefaultValue="Clause" />
        <bt:String id="Dock.Button.eSign.Label" DefaultValue="eSign" />
        <bt:String id="Dock.Button.Templates.Label" DefaultValue="Templates" />
        <bt:String id="Dock.GetStarted.Title" DefaultValue="Dock contract addins." />
      </bt:ShortStrings>
      <bt:LongStrings>
        <bt:String id="Dock.Button.CL.Tooltip" DefaultValue="Contract Clause Library" />
        <bt:String id="Dock.Button.eSign.Tooltip" DefaultValue="Contract eSign" />
        <bt:String id="Dock.Button.Templates.Tooltip" DefaultValue="Contract Templates" />        
        <bt:String id="Dock.GetStarted.Description" DefaultValue="Dock add-in for contract word documents." />
      </bt:LongStrings>
    </Resources>
    <WebApplicationInfo>
      <Id>\${ApplicationId}</Id>
      <Resource>api://\${BackendDomain}/\${ApplicationId}</Resource>
      <Scopes>
        <Scope>openid</Scope>
        <Scope>offline_access</Scope>
        <Scope>profile</Scope>
      </Scopes>
    </WebApplicationInfo>
  </VersionOverrides>
</OfficeApp>
`;
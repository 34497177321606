/* eslint-disable max-len */
export const backendServiceTemplateV4_9 = `[
  {
    "name": "ACCESS_CONTROLL_URL",
    "value": "https://accounts.accesscontrol.windows.net",
    "slotSetting": false
  },
  {
    "name": "AI_HOSTNAME",
    "value": "\${AI_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "AI_PORT",
    "value": "443",
    "slotSetting": false
  },
  {
    "name": "AUTHENTICATION_METHOD",
    "value": "\${AUTHENTICATION_METHOD}",
    "slotSetting": false
  },
  {
    "name": "AUTHORITY",
    "value": "\${AUTHORITY}",
    "slotSetting": false
  },
  {
    "name": "AZURE_OCR_API_KEY",
    "value": "\${AZURE_OCR_API_KEY}",
    "slotSetting": false
  },
  {
    "name": "AZURE_OCR_ENDPOINT",
    "value": "\${AZURE_OCR_ENDPOINT}",
    "slotSetting": false
  },
  {
    "name": "BASE_URL",
    "value": "\${BASE_URL}",
    "slotSetting": false
  },
  {
    "name": "CERTIFICATE_NAME",
    "value": "\${CERTIFICATE_NAME}",
    "slotSetting": false
  },
   {
    "name": "CRM_CONFIG_PATH",
    "value": "\${CRM_CONFIG_PATH}",
    "slotSetting": false
  },
   {
    "name": "CRM_CONFIG_SECRET",
    "value": "\${CRM_CONFIG_SECRET}",
    "slotSetting": false
  },
  {
    "name": "CSP_DefaultSrc",
    "value": "\${CSP_DefaultSrc}",
    "slotSetting": false
  },
  {
    "name": "CSP_FontSrc",
    "value": "\${CSP_FontSrc}",
    "slotSetting": false
  },
  {
    "name": "CSP_frameAncestors",
    "value": "\${CSP_frameAncestors}",
    "slotSetting": false
  },
  {
    "name": "CSP_frameSrc",
    "value": "\${CSP_frameSrc}",
    "slotSetting": false
  },
  {
    "name": "CSP_ImgSrc",
    "value": "\${CSP_ImgSrc}",
    "slotSetting": false
  },
  {
    "name": "CSP_ReportOnly",
    "value": "\${CSP_ReportOnly}",
    "slotSetting": false
  },
  {
    "name": "CSP_ScriptSrc",
    "value": "\${CSP_ScriptSrc}",
    "slotSetting": false
  },
  {
    "name": "CSP_StyleSrc",
    "value": "\${CSP_StyleSrc}",
    "slotSetting": false
  },
  {
    "name": "CSP_ValidConnectSrc",
    "value": "\${CSP_ValidConnectSrc}",
    "slotSetting": false
  },
  {
    "name": "Holiday_Dates",
    "value": "\${Holiday_Dates}",
    "slotSetting": false
  },
  {
    "name": "INTERNAL_DOMAINS",
    "value": "\${INTERNAL_DOMAINS}",
    "slotSetting": false
  },

  {
    "name": "CLAUSE_MODEL_PATH",
    "value": "//model-clause-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "CLIENT_ID",
    "value": "\${CLIENT_ID}",
    "slotSetting": false
  },
  {
    "name": "COMPANY_NAMES",
    "value": "\${COMPANY_NAMES}",
    "slotSetting": false
  },
  {
    "name": "DB_CACHE_SYNC_CRON_EXPRESSION",
    "value": "0 */2 * * *",
    "slotSetting": false
  },
  {
    "name": "DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "DISABLE_PARALLEL_OCR",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "DOCK_APP_SECRET",
    "value": "\${DOCK_APP_SECRET}",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_PASSWORD",
    "value": "dckr_pat_dtE3DqKsSiGIc4rywZrnNt-Qd-k",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_URL",
    "value": "https://index.docker.io/v1",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_USERNAME",
    "value": "dock365usa",
    "slotSetting": false
  },
  {
    "name": "DOMAIN",
    "value": "\${DOMAIN}",
    "slotSetting": false
  },
  {
    "name": "DOTNET_HOSTNAME",
    "value": "\${DOTNET_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "ENABLE_LOG",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "ENABLE_TEST_API",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "ENABLE_CSP",
    "value": "\${ENABLE_CSP}",
    "slotSetting": false
  },
  {
    "name": "ENABLE_API_XForward",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "ENTITY_MODEL_VERSION",
    "value": "\${ENTITY_MODEL_VERSION}",
    "slotSetting": false
  },
  {
    "name": "ENTITY_MODEL_PATH",
    "value": "//model-entity-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "FILEUPLOAD_LIMIT_IN_MB",
    "value": "26",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_DEFAULT_CONCURRENCY",
    "value": "2",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_MAX_CONCURRENCY",
    "value": "5",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_MAX_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },

  {
    "name": "KEY_VAULT_APP_SECRET_NAME",
    "value": "\${KEY_VAULT_APP_SECRET_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_KEY_NAME",
    "value": "\${KEY_VAULT_KEY_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_URL",
    "value": "\${KEY_VAULT_URL}",
    "slotSetting": false
  },
  {
    "name": "LOG_EMAIL",
    "value": "support@mydock365.com",
    "slotSetting": false
  },
  {
    "name": "LOG_LEVEL",
    "value": "debug",
    "slotSetting": false
  },
  {
    "name": "LOG_PASSWORD",
    "value": "\${LOG_PASSWORD}",
    "slotSetting": false
  },
  {
    "name": "LOG_PATH",
    "value": "/home/logs",
    "slotSetting": false
  },
  {
    "name": "LOG_TOKEN",
    "value": "\${LOG_TOKEN}",
    "slotSetting": false
  },
  {
    "name": "MAX_CONCURRENCY",
    "value": "3",
    "slotSetting": false
  },
  {
    "name": "MAX_ENTITY_CHAR",
    "value": "100000",
    "slotSetting": false
  },
  {
    "name": "MONGO_URI",
    "value": "MongoConnectionString",
    "slotSetting": false
  },
  {
    "name": "NODE_ENV",
    "value": "production",
    "slotSetting": false
  },
  {
    "name": "NOTIFICATION_CRON_EXPRESSION",
    "value": "30 0  * * *",
    "slotSetting": false
  },
  {
    "name": "OCR_DEPLOYMENT_NAME",
    "value": "\${OCR_DEPLOYMENT_NAME}",
    "slotSetting": false
  },
  {
    "name": "OCR_ENGINE",
    "value": "\${OCR_ENGINE}",
    "slotSetting": false
  },
  {
    "name": "OCR_OPENAI_API_VERSION",
    "value": "\${OCR_OPENAI_API_VERSION}",
    "slotSetting": false
  },
  {
    "name": "OCR_OPENAI_AZURE_ENDPOINT",
    "value": "\${OCR_OPENAI_AZURE_ENDPOINT}",
    "slotSetting": false
  },
  {
    "name": "OCR_OPENAI_KEY",
    "value": "\${OCR_OPENAI_KEY}",
    "slotSetting": false
  },
  {
    "name": "Off_Days",
    "value": "\${Off_Days}",
    "slotSetting": false
  },
  {
    "name": "ORIGIN",
    "value": "\${ORIGIN}",
    "slotSetting": false
  },
  {
    "name": "PORT",
    "value": "80",
    "slotSetting": false
  },
  {
    "name": "PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "REQUEST_PAYLOAD_LIMIT",
    "value": "250mb",
    "slotSetting": false
  },
  {
    "name": "RESOURCE_ID",
    "value": "00000003-0000-0ff1-ce00-000000000000",
    "slotSetting": false
  },
  {
    "name": "SET_TRUST_PROXY",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "SESSION_SECRET",
    "value": "\${SESSION_SECRET}",
    "slotSetting": false
  },
  {
    "name": "SINGLE_ORG_APP_REG_ENABLED",
    "value": "\${SINGLE_ORG_APP_REG_ENABLED}",
    "slotSetting": false
  },
  {
    "name": "SITE_URL",
    "value":"\${SITE_URL}",
    "slotSetting": false
  },
  {
    "name": "SP_WEBHOOK",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "SYNC_DATA_ON_START",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "TENANT_ID",
    "value": "\${TENANT_ID}",
    "slotSetting": false
  },
  {
    "name": "THUMB_PRINT",
    "value": "\${THUMB_PRINT}",
    "slotSetting": false
  },
  {
    "name": "USE_DEFAULT_AI",
    "value": "\${USE_DEFAULT_AI}",
    "slotSetting": false
  },
  {
    "name": "USE_MODERN_AUTH",
    "value": "\${USE_MODERN_AUTH}",
    "slotSetting": false
  },
  {
    "name": "WEBSITE_TIME_ZONE",
    "value": "\${WEBSITE_TIME_ZONE}",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_CONTAINER_START_TIME_LIMIT",
    "value": "1800",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_ENABLE_APP_SERVICE_STORAGE",
    "value": "false",
    "slotSetting": false
  }
]`;


export const backendServiceTemplateV4_8 = `[
  {
    "name": "ACCESS_CONTROLL_URL",
    "value": "https://accounts.accesscontrol.windows.net",
    "slotSetting": false
  },
  {
    "name": "AI_HOSTNAME",
    "value": "\${AI_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "AI_PORT",
    "value": "443",
    "slotSetting": false
  },
  {
    "name": "AUTHENTICATION_METHOD",
    "value": "\${AUTHENTICATION_METHOD}",
    "slotSetting": false
  },
  {
    "name": "AUTHORITY",
    "value": "\${AUTHORITY}",
    "slotSetting": false
  },
  {
    "name": "AZURE_OCR_API_KEY",
    "value": "\${AZURE_OCR_API_KEY}",
    "slotSetting": false
  },
  {
    "name": "AZURE_OCR_ENDPOINT",
    "value": "\${AZURE_OCR_ENDPOINT}",
    "slotSetting": false
  },
  {
    "name": "BASE_URL",
    "value": "\${BASE_URL}",
    "slotSetting": false
  },
  {
    "name": "CERTIFICATE_NAME",
    "value": "\${CERTIFICATE_NAME}",
    "slotSetting": false
  },
  {
    "name": "CLAUSE_MODEL_PATH",
    "value": "//model-clause-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "CLIENT_ID",
    "value": "\${CLIENT_ID}",
    "slotSetting": false
  },
  {
    "name": "COMPANY_NAMES",
    "value": "\${COMPANY_NAMES}",
    "slotSetting": false
  },
  {
    "name": "DB_CACHE_SYNC_CRON_EXPRESSION",
    "value": "0 */2 * * *",
    "slotSetting": false
  },
  {
    "name": "DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "DISABLE_PARALLEL_OCR",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "DOCK_APP_SECRET",
    "value": "\${DOCK_APP_SECRET}",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_PASSWORD",
    "value": "dckr_pat_dtE3DqKsSiGIc4rywZrnNt-Qd-k",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_URL",
    "value": "https://index.docker.io/v1",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_USERNAME",
    "value": "dock365usa",
    "slotSetting": false
  },
  {
    "name": "DOMAIN",
    "value": "\${DOMAIN}",
    "slotSetting": false
  },
  {
    "name": "DOTNET_HOSTNAME",
    "value": "\${DOTNET_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "ENABLE_LOG",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "ENTITY_MODEL_VERSION",
    "value": "\${ENTITY_MODEL_VERSION}",
    "slotSetting": false
  },
  {
    "name": "ENTITY_MODEL_PATH",
    "value": "//model-entity-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "FILEUPLOAD_LIMIT_IN_MB",
    "value": "26",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_DEFAULT_CONCURRENCY",
    "value": "2",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_MAX_CONCURRENCY",
    "value": "5",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_MAX_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },

  {
    "name": "KEY_VAULT_APP_SECRET_NAME",
    "value": "\${KEY_VAULT_APP_SECRET_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_KEY_NAME",
    "value": "\${KEY_VAULT_KEY_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_URL",
    "value": "\${KEY_VAULT_URL}",
    "slotSetting": false
  },
  {
    "name": "LOG_EMAIL",
    "value": "support@mydock365.com",
    "slotSetting": false
  },
  {
    "name": "LOG_LEVEL",
    "value": "debug",
    "slotSetting": false
  },
  {
    "name": "LOG_PASSWORD",
    "value": "\${LOG_PASSWORD}",
    "slotSetting": false
  },
  {
    "name": "LOG_PATH",
    "value": "/home/logs",
    "slotSetting": false
  },
  {
    "name": "LOG_TOKEN",
    "value": "\${LOG_TOKEN}",
    "slotSetting": false
  },
  {
    "name": "MAX_CONCURRENCY",
    "value": "3",
    "slotSetting": false
  },
  {
    "name": "MAX_ENTITY_CHAR",
    "value": "100000",
    "slotSetting": false
  },
  {
    "name": "MONGO_URI",
    "value": "\${MONGO_URI}",
    "slotSetting": false
  },
  {
    "name": "NODE_ENV",
    "value": "production",
    "slotSetting": false
  },
  {
    "name": "NOTIFICATION_CRON_EXPRESSION",
    "value": "30 0  * * *",
    "slotSetting": false
  },
  {
    "name": "OCR_DEPLOYMENT_NAME",
    "value": "\${OCR_DEPLOYMENT_NAME}",
    "slotSetting": false
  },
  {
    "name": "OCR_ENGINE",
    "value": "\${OCR_ENGINE}",
    "slotSetting": false
  },
  {
    "name": "OCR_OPENAI_API_VERSION",
    "value": "\${OCR_OPENAI_API_VERSION}",
    "slotSetting": false
  },
  {
    "name": "OCR_OPENAI_AZURE_ENDPOINT",
    "value": "\${OCR_OPENAI_AZURE_ENDPOINT}",
    "slotSetting": false
  },
  {
    "name": "OCR_OPENAI_KEY",
    "value": "\${OCR_OPENAI_KEY}",
    "slotSetting": false
  },
  {
    "name": "ORIGIN",
    "value": "\${ORIGIN}",
    "slotSetting": false
  },
  {
    "name": "PORT",
    "value": "80",
    "slotSetting": false
  },
  {
    "name": "PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "REQUEST_PAYLOAD_LIMIT",
    "value": "250mb",
    "slotSetting": false
  },
  {
    "name": "RESOURCE_ID",
    "value": "00000003-0000-0ff1-ce00-000000000000",
    "slotSetting": false
  },
  {
    "name": "SESSION_SECRET",
    "value": "\${SESSION_SECRET}",
    "slotSetting": false
  },
  {
    "name": "SINGLE_ORG_APP_REG_ENABLED",
    "value": "\${SINGLE_ORG_APP_REG_ENABLED}",
    "slotSetting": false
  },
  {
    "name": "SITE_URL",
    "value":"\${SITE_URL}",
    "slotSetting": false
  },
  {
    "name": "SP_WEBHOOK",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "SYNC_DATA_ON_START",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "TENANT_ID",
    "value": "\${TENANT_ID}",
    "slotSetting": false
  },
  {
    "name": "THUMB_PRINT",
    "value": "\${THUMB_PRINT}",
    "slotSetting": false
  },
  {
    "name": "USE_DEFAULT_AI",
    "value": "\${USE_DEFAULT_AI}",
    "slotSetting": false
  },
  {
    "name": "USE_MODERN_AUTH",
    "value": "\${USE_MODERN_AUTH}",
    "slotSetting": false
  },
  {
    "name": "WEBSITE_TIME_ZONE",
    "value": "\${WEBSITE_TIME_ZONE}",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_CONTAINER_START_TIME_LIMIT",
    "value": "1800",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_ENABLE_APP_SERVICE_STORAGE",
    "value": "false",
    "slotSetting": false
  }
]`;

export const backendServiceTemplateV4_7 = `[
  {
    "name": "ACCESS_CONTROLL_URL",
    "value": "https://accounts.accesscontrol.windows.net",
    "slotSetting": false
  },
  {
    "name": "AI_HOSTNAME",
    "value": "\${AI_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "AI_PORT",
    "value": "443",
    "slotSetting": false
  },
  {
    "name": "AUTHENTICATION_METHOD",
    "value": "\${AUTHENTICATION_METHOD}",
    "slotSetting": false
  },
  {
    "name": "AUTHORITY",
    "value": "\${AUTHORITY}",
    "slotSetting": false
  },
  {
    "name": "BASE_URL",
    "value": "\${BASE_URL}",
    "slotSetting": false
  },
  {
    "name": "CERTIFICATE_NAME",
    "value": "\${CERTIFICATE_NAME}",
    "slotSetting": false
  },
  {
    "name": "CLAUSE_MODEL_PATH",
    "value": "//model-clause-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "CLIENT_ID",
    "value": "\${CLIENT_ID}",
    "slotSetting": false
  },
  {
    "name": "COMPANY_NAMES",
    "value": "\${COMPANY_NAMES}",
    "slotSetting": false
  },
  {
    "name": "DB_CACHE_SYNC_CRON_EXPRESSION",
    "value": "0 */2 * * *",
    "slotSetting": false
  },
  {
    "name": "DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "DISABLE_PARALLEL_OCR",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "DOCK_APP_SECRET",
    "value": "\${DOCK_APP_SECRET}",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_PASSWORD",
    "value": "dckr_pat_dtE3DqKsSiGIc4rywZrnNt-Qd-k",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_URL",
    "value": "https://index.docker.io/v1",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_USERNAME",
    "value": "dock365usa",
    "slotSetting": false
  },
  {
    "name": "DOMAIN",
    "value": "\${DOMAIN}",
    "slotSetting": false
  },
  {
    "name": "DOTNET_HOSTNAME",
    "value": "\${DOTNET_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "ENABLE_LOG",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "ENTITY_MODEL_PATH",
    "value": "//model-entity-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "FILEUPLOAD_LIMIT_IN_MB",
    "value": "26",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_DEFAULT_CONCURRENCY",
    "value": "2",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_MAX_CONCURRENCY",
    "value": "5",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_MAX_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },

  {
    "name": "KEY_VAULT_APP_SECRET_NAME",
    "value": "\${KEY_VAULT_APP_SECRET_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_KEY_NAME",
    "value": "\${KEY_VAULT_KEY_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_URL",
    "value": "\${KEY_VAULT_URL}",
    "slotSetting": false
  },
  {
    "name": "LOG_EMAIL",
    "value": "support@mydock365.com",
    "slotSetting": false
  },
  {
    "name": "LOG_LEVEL",
    "value": "debug",
    "slotSetting": false
  },
  {
    "name": "LOG_PASSWORD",
    "value": "\${LOG_PASSWORD}",
    "slotSetting": false
  },
  {
    "name": "LOG_PATH",
    "value": "/home/logs",
    "slotSetting": false
  },
  {
    "name": "LOG_TOKEN",
    "value": "\${LOG_TOKEN}",
    "slotSetting": false
  },
  {
    "name": "MAX_CONCURRENCY",
    "value": "3",
    "slotSetting": false
  },
  {
    "name": "MAX_ENTITY_CHAR",
    "value": "100000",
    "slotSetting": false
  },
  {
    "name": "MONGO_URI",
    "value": "\${MONGO_URI}",
    "slotSetting": false
  },
  {
    "name": "NODE_ENV",
    "value": "production",
    "slotSetting": false
  },
  {
    "name": "NOTIFICATION_CRON_EXPRESSION",
    "value": "30 0  * * *",
    "slotSetting": false
  },
  {
    "name": "ORIGIN",
    "value": "\${ORIGIN}",
    "slotSetting": false
  },
  {
    "name": "PORT",
    "value": "80",
    "slotSetting": false
  },
  {
    "name": "PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "RESOURCE_ID",
    "value": "00000003-0000-0ff1-ce00-000000000000",
    "slotSetting": false
  },
  {
    "name": "SESSION_SECRET",
    "value": "\${SESSION_SECRET}",
    "slotSetting": false
  },
  {
    "name": "SINGLE_ORG_APP_REG_ENABLED",
    "value": "\${SINGLE_ORG_APP_REG_ENABLED}",
    "slotSetting": false
  },
  {
    "name": "SITE_URL",
    "value":"\${SITE_URL}",
    "slotSetting": false
  },
  {
    "name": "SP_WEBHOOK",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "SYNC_DATA_ON_START",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "TENANT_ID",
    "value": "\${TENANT_ID}",
    "slotSetting": false
  },
  {
    "name": "THUMB_PRINT",
    "value": "\${THUMB_PRINT}",
    "slotSetting": false
  },
  {
    "name": "USE_DEFAULT_AI",
    "value": "\${USE_DEFAULT_AI}",
    "slotSetting": false
  },
  {
    "name": "USE_MODERN_AUTH",
    "value": "\${USE_MODERN_AUTH}",
    "slotSetting": false
  },
  {
    "name": "WEBSITE_TIME_ZONE",
    "value": "\${WEBSITE_TIME_ZONE}",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_CONTAINER_START_TIME_LIMIT",
    "value": "1800",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_ENABLE_APP_SERVICE_STORAGE",
    "value": "false",
    "slotSetting": false
  }
]`;





export const backendServiceTemplateV4_6 = `[
  {
    "name": "ACCESS_CONTROLL_URL",
    "value": "https://accounts.accesscontrol.windows.net",
    "slotSetting": false
  },
  {
    "name": "AI_HOSTNAME",
    "value": "\${AI_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "AI_PORT",
    "value": "443",
    "slotSetting": false
  },
  {
    "name": "AUTHENTICATION_METHOD",
    "value": "\${AUTHENTICATION_METHOD}",
    "slotSetting": false
  },
  {
    "name": "AUTHORITY",
    "value": "\${AUTHORITY}",
    "slotSetting": false
  },
  {
    "name": "BASE_URL",
    "value": "\${BASE_URL}",
    "slotSetting": false
  },
  {
    "name": "CERTIFICATE_NAME",
    "value": "\${CERTIFICATE_NAME}",
    "slotSetting": false
  },
  {
    "name": "CLAUSE_MODEL_PATH",
    "value": "//model-clause-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "CLIENT_ID",
    "value": "\${CLIENT_ID}",
    "slotSetting": false
  },
  {
    "name": "COMPANY_NAMES",
    "value": "\${COMPANY_NAMES}",
    "slotSetting": false
  },
  {
    "name": "DB_CACHE_SYNC_CRON_EXPRESSION",
    "value": "0 */2 * * *",
    "slotSetting": false
  },
  {
    "name": "DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "DISABLE_PARALLEL_OCR",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "DOCK_APP_SECRET",
    "value": "\${DOCK_APP_SECRET}",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_PASSWORD",
    "value": "dckr_pat_dtE3DqKsSiGIc4rywZrnNt-Qd-k",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_URL",
    "value": "https://index.docker.io/v1",
    "slotSetting": false
  },
  {
    "name": "DOCKER_REGISTRY_SERVER_USERNAME",
    "value": "dock365usa",
    "slotSetting": false
  },
  {
    "name": "DOMAIN",
    "value": "\${DOMAIN}",
    "slotSetting": false
  },
  {
    "name": "DOTNET_HOSTNAME",
    "value": "\${DOTNET_HOSTNAME}",
    "slotSetting": false
  },
  {
    "name": "ENABLE_LOG",
    "value": "true",
    "slotSetting": false
  },
 
  {
    "name": "ENTITY_MODEL_PATH",
    "value": "//model-entity-v1//model-best",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_DEFAULT_CONCURRENCY",
    "value": "2",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_MAX_CONCURRENCY",
    "value": "5",
    "slotSetting": false
  },
  {
    "name": "GENERAL_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_DEFAULT_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_MAX_CONCURRENCY",
    "value": "1",
    "slotSetting": false
  },
  {
    "name": "SCHEDULE_QUEUE_PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },

  {
    "name": "KEY_VAULT_APP_SECRET_NAME",
    "value": "\${KEY_VAULT_APP_SECRET_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_KEY_NAME",
    "value": "\${KEY_VAULT_KEY_NAME}",
    "slotSetting": false
  },
  {
    "name": "KEY_VAULT_URL",
    "value": "\${KEY_VAULT_URL}",
    "slotSetting": false
  },
  {
    "name": "LOG_EMAIL",
    "value": "support@mydock365.com",
    "slotSetting": false
  },
  {
    "name": "LOG_LEVEL",
    "value": "debug",
    "slotSetting": false
  },
  {
    "name": "LOG_PASSWORD",
    "value": "\${LOG_PASSWORD}",
    "slotSetting": false
  },
  {
    "name": "LOG_PATH",
    "value": "/home/logs",
    "slotSetting": false
  },
  {
    "name": "LOG_TOKEN",
    "value": "\${LOG_TOKEN}",
    "slotSetting": false
  },
  {
    "name": "MAX_CONCURRENCY",
    "value": "3",
    "slotSetting": false
  },
  {
    "name": "MAX_ENTITY_CHAR",
    "value": "100000",
    "slotSetting": false
  },
  {
    "name": "MONGO_URI",
    "value": "\${MONGO_URI}",
    "slotSetting": false
  },
  {
    "name": "NODE_ENV",
    "value": "production",
    "slotSetting": false
  },
  {
    "name": "NOTIFICATION_CRON_EXPRESSION",
    "value": "30 0  * * *",
    "slotSetting": false
  },
  {
    "name": "ORIGIN",
    "value": "\${ORIGIN}",
    "slotSetting": false
  },
  {
    "name": "PORT",
    "value": "80",
    "slotSetting": false
  },
  {
    "name": "PROCESS_EVERY",
    "value": "30 seconds",
    "slotSetting": false
  },
  {
    "name": "RESOURCE_ID",
    "value": "00000003-0000-0ff1-ce00-000000000000",
    "slotSetting": false
  },
  {
    "name": "SESSION_SECRET",
    "value": "\${SESSION_SECRET}",
    "slotSetting": false
  },
  {
    "name": "SINGLE_ORG_APP_REG_ENABLED",
    "value": "\${SINGLE_ORG_APP_REG_ENABLED}",
    "slotSetting": false
  },
  {
    "name": "SITE_URL",
    "value":"\${SITE_URL}",
    "slotSetting": false
  },
  {
    "name": "SP_WEBHOOK",
    "value": "true",
    "slotSetting": false
  },
  {
    "name": "SYNC_DATA_ON_START",
    "value": "false",
    "slotSetting": false
  },
  {
    "name": "TENANT_ID",
    "value": "\${TENANT_ID}",
    "slotSetting": false
  },
  {
    "name": "THUMB_PRINT",
    "value": "\${THUMB_PRINT}",
    "slotSetting": false
  },
  {
    "name": "USE_DEFAULT_AI",
    "value": "\${USE_DEFAULT_AI}",
    "slotSetting": false
  },
  {
    "name": "USE_MODERN_AUTH",
    "value": "\${USE_MODERN_AUTH}",
    "slotSetting": false
  },
  {
    "name": "WEBSITE_TIME_ZONE",
    "value": "\${WEBSITE_TIME_ZONE}",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_CONTAINER_START_TIME_LIMIT",
    "value": "1800",
    "slotSetting": false
  },
  {
    "name": "WEBSITES_ENABLE_APP_SERVICE_STORAGE",
    "value": "false",
    "slotSetting": false
  }
]`;




export const backendServiceTemplateV4_5 = `version: "3.3"
services:
  redis:
    image: mydock365/redis:\${RedisBuild}
    restart: unless-stopped
    expose:
      - 6379
    networks:
      - app-network
  app:
    restart: unless-stopped
    image: dock365usa/backend-service:\${BuildId}
    container_name: dock365usa/backend-service
    environment:
      - NODE_ENV=production
      - REDIS_HOSTNAME=redis
      - REDIS_PORT=6379

      - PORT=8080
      - BASE_URL=\${BaseUrl}
      - ORIGIN=\${Origin}
      - SESSION_SECRET=\${SessionSecret}
      - AUTHENTICATION_METHOD=\${AuthenticationMethod}
      - NOTIFICATION_CRON_EXPRESSION=\${NotificationCron}
      - DISABLE_PARALLEL_OCR=true
      
      - LOG_TOKEN=\${LogToken}
      - LOG_PATH=\${LogPath}
      - LOG_LEVEL=\${LogLevel}
      - LOG_EMAIL=\${LogEmail}
      - LOG_PASSWORD=\${LogPassword}

      - MONGO_URI=\${MongoUri}
      - SYNC_DATA_ON_START=false
      - SP_WEBHOOK=true
      - DB_CACHE_SYNC_CRON_EXPRESSION=0 */2 * * *
      
      - TENANT_ID=\${TenantId}
      - DOMAIN=\${Domain}
      - SITE_URL=\${SiteUrl}
      - CLIENT_ID=\${ClientId}
      - SINGLE_ORG_APP_REG_ENABLED=\${SingleOrgAppRegEnabled}
      
      - ACCESS_CONTROLL_URL=https://accounts.accesscontrol.windows.net
      - RESOURCE_ID=00000003-0000-0ff1-ce00-000000000000
      - USE_MODERN_AUTH=\${UseModernAuth}
      - CERTIFICATE_NAME=\${CertificateName}
      - THUMB_PRINT=\${ThumbPrint}
      - AUTHORITY=\${Authority}
      - KEY_VAULT_URL=\${KeyVaultURL}
      - KEY_VAULT_KEY_NAME=\${RSAKey}
      - KEY_VAULT_APP_SECRET_NAME=\${AppSecret}

      - DOCK_APP_SECRET=\${DockAppSecret}

      - AI_HOSTNAME=\${AiHostName}
      - AI_PORT=\${AiPort}
      - COMPANY_NAMES="\${CompanyName}"
      - MAX_ENTITY_CHAR=\${MaxEntityChar}
      - USE_DEFAULT_AI=\${AiEnabled}
      - CLAUSE_MODEL_PATH=\${ClauseModelPath}
      - ENTITY_MODEL_PATH=\${EntityModelPath}
      
      - DOTNET_HOSTNAME=\${DotnetHostName}

    ports:
      - "8080:8080"
    volumes:
      - app:/home/node/app
      - node_modules:/home/node/app/node_modules
      - log:\${LogPath}
    networks:
    - app-network
networks:
  app-network:
    driver: bridge
volumes:
  node_modules:
  app:
  log:`;

export const backendServiceTemplateV4_4 = `version: "3.3"
services:
  redis:
    image: mydock365/redis:\${RedisBuild}
    restart: unless-stopped
    expose:
      - 6379
    networks:
      - app-network
  app:
    restart: unless-stopped
    image: dock365usa/backend-service:\${BuildId}
    container_name: dock365usa/backend-service
    environment:
      - NODE_ENV=production
      - PORT=8080
      - SESSION_SECRET=\${SessionSecret}
      - ORIGIN=\${Origin}
      - MONGO_URI=\${MongoUri}
      - SYNC_DATA_ON_START=false
      - SP_WEBHOOK=true
      - DB_CACHE_SYNC_CRON_EXPRESSION=0 */2 * * *
      - DISABLE_PARALLEL_OCR=true
      - BASE_URL=\${BaseUrl}
      - REDIS_HOSTNAME=redis
      - REDIS_PORT=6379
      - SITE_URL=\${SiteUrl}
      - CLIENT_ID=\${ClientId}
      - SingleOrgAppRegEnabled=\${SingleOrgAppRegEnabled}
      - TENANT_ID=\${TenantId}
      - NOTIFICATION_CRON_EXPRESSION=30 0 * * *
      - KEY_VAULT_URL=\${KeyVaultURL}
      - KEY_VAULT_KEY_NAME=\${RSAKey}
      - KEY_VAULT_APP_SECRET_NAME=\${AppSecret}

      - AI_HOSTNAME=\${AiHostName}
      - AI_PORT=\${AiPort}
      - MAX_ENTITY_CHAR=\${MaxEntityChar}
      - USE_DEFAULT_AI=\${AiEnabled}
      - CLAUSE_MODEL_PATH=\${ClauseModelPath}
      - ENTITY_MODEL_PATH=\${EntityModelPath}
      - COMPANY_NAMES="\${CompanyName}"
      
      - DOTNET_HOSTNAME=\${DotnetHostname}
      - DOCK_APP_SECRET=53E0E5C6DF1524F6AD446A3ACB9A1AE821FB8EF6CD6B95113B82EC44D041A3F4

    ports:
      - "8080:8080"
    volumes:
      - app:/home/node/app
      - node_modules:/home/node/app/node_modules
    networks:
    - app-network
networks:
  app-network:
    driver: bridge
volumes:
  node_modules:
  app:`;

export const backendServiceTemplateV4_3 = `version: "3.3"
services:
  db:
    image: mongo:6.0.2
    restart: unless-stopped
    environment:
      - MONGO_INITDB_ROOT_USERNAME=root
      - MONGO_INITDB_ROOT_PASSWORD=root
    volumes:
      - dbdata:/data/db
    networks:
      - app-network
  redis:
    image: mydock365/redis:\${RedisBuild}
    restart: unless-stopped
    expose:
      - 6379
    networks:
      - app-network
  app:
    restart: unless-stopped
    image: dock365usa/backend-service:\${BuildId}
    container_name: dock365usa/backend-service
    environment:
      - NODE_ENV=production
      - PORT=8080
      - ORIGIN=\${Origin}
      - MONGO_USERNAME=root
      - MONGO_PASSWORD=root
      - MONGO_PORT=27017
      - MONGO_DB=CMSBackendServices
      - BASE_URL=\${BaseUrl}
      - MONGO_HOSTNAME=db
      - REDIS_HOSTNAME=redis
      - REDIS_PORT=6379
      - SITE_URL=\${SiteUrl}
      - CLIENT_ID=\${ClientId}
      - CONTRACT_LIBRARY_ID=\${ContractLibraryId}
      - REQUEST_LIBRARY_ID=\${RequestLibraryId}
      - OUTLOOK_CLIENT_ID=\${OutlookClientId}
      - OUTLOOK_CLIENT_SECRET=\${OutlookClientSecret}
      - OUTLOOK_TENANT=\${AppRegOrgTenantId}
      - TENANT_NAME=\${TenantName}
      - TENANT_ID=\${TenantId}
      - NOTIFICATION_CRON_EXPRESSION=\${NotificationCron}
      - KEY_VAULT_URL=\${KeyVaultURL}
      - KEY_VAULT_KEY_NAME=\${RSAKey}
      - KEY_VAULT_APP_SECRET_NAME=\${AppSecret}

      - AI_HOSTNAME=\${AiHostName}
      - AI_PORT=\${AiPort}
      - MAX_ENTITY_CHAR=\${MaxEntityChar}
      - USE_DEFAULT_AI=\${AiEnabled}
      - CLAUSE_MODEL_PATH=\${ClauseModelPath}
      - ENTITY_MODEL_PATH=\${EntityModelPath}
      - COMPANY_NAMES="\${CompanyName}"

    ports:
      - "8080:8080"
    volumes:
      - app:/home/node/app
      - node_modules:/home/node/app/node_modules
    networks:
      - app-network
\${AiImageConfig}
networks:
  app-network:
    driver: bridge
volumes:
  dbdata:
  node_modules:
  app:`;

export const backendServiceTemplateV4_1 = `version: "3.3"
services:
  db:
    image: mongo
    restart: unless-stopped
    environment:
      - MONGO_INITDB_ROOT_USERNAME=root
      - MONGO_INITDB_ROOT_PASSWORD=root
    volumes:
      - dbdata:/data/db
    networks:
      - app-network
  redis:
    image: mydock365/redis:\${RedisBuild}
    restart: unless-stopped
    expose:
      - 6379
    networks:
      - app-network
  app:
    restart: unless-stopped
    image: dock365usa/backend-service:\${BuildId}
    container_name: dock365usa/backend-service
    environment:
      - NODE_ENV=production
      - PORT=8080
      - ORIGIN=\${Origin}
      - MONGO_USERNAME=root
      - MONGO_PASSWORD=root
      - MONGO_PORT=27017
      - MONGO_DB=CMSBackendServices
      - BASE_URL=\${BaseUrl}
      - MONGO_HOSTNAME=db
      - REDIS_HOSTNAME=redis
      - REDIS_PORT=6379
      - SITE_URL=\${SiteUrl}
      - CLIENT_ID=\${ClientId}
      - CLIENT_SECRET=\${ClientSecret}
    ports:
      - "8080:8080"
    volumes:
      - app:/home/node/app
      - node_modules:/home/node/app/node_modules
    networks:
      - app-network
networks:
  app-network:
    driver: bridge
volumes:
  dbdata:
  node_modules:
  app:`;

export const backendServiceTemplateV4_0 = `version: "3.3"
services:
  db:
    image: mongo
    restart: unless-stopped
    environment:
      - MONGO_INITDB_ROOT_USERNAME=root
      - MONGO_INITDB_ROOT_PASSWORD=root
    volumes:
      - dbdata:/data/db
    networks:
      - app-network

  app:
    restart: unless-stopped
    image: mydock365/cms-backend-services:\${BuildId}
    container_name: mydock365/cms-backend-services
    environment:
      - NODE_ENV=production
      - PORT=8080
      - ORIGIN=\${Origin}
      - MONGO_USERNAME=root
      - MONGO_PASSWORD=root
      - MONGO_PORT=27017
      - MONGO_DB=CMSBackendServices
      - BASE_URL=\${BaseUrl}
      - MONGO_HOSTNAME=db
      - SITE_URL=\${SiteUrl}
      - CLIENT_ID=\${ClientId}
      - CLIENT_SECRET=\${ClientSecret}
      - DOCUSIGN_SECRET=\${DocusignSecret}
      - DOCUSIGN_INTEGRATION_KEY=\${DocusignIntegrationKey}
      - DOCUSIGN_ACCOUNT_ID=\${DocusignAccountId}
      - DOCUSIGN_CONNECT_SECRET=\${DocusignConnectSecret}
      - SERTIFY_API_DOMAIN=\${SertifyApiDomain}
      - SERTIFY_API_KEY=\${SertifyApiKey}
      - SERTIFY_WEBHOOK_TOKEN=\${SertifyWebhookToken}
    ports:
      - "8080:8080"
    volumes:
      - app:/home/node/app
      - node_modules:/home/node/app/node_modules
    networks:
      - app-network
networks:
  app-network:
    driver: bridge
volumes:
  dbdata:
  node_modules:
  app:`;
import React from "react";
import { Flex } from "@chakra-ui/react";

const FormContainer: React.FC<IProps> = ({ children }) => {
  return (
    <Flex
      mx="auto"
      my="2"
      width="100%"
      maxW="90vw"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {children}
    </Flex>
  );
};

export default FormContainer;

interface IProps {
  children: React.ReactNode;
}
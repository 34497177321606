/* eslint-disable max-len */
export const teamsAppTemplateV4_2 = `{
  "$schema": "https://developer.microsoft.com/en-us/json-schemas/teams/v1.9/MicrosoftTeams.schema.json",
  "manifestVersion": "1.9",
  "version": "1.0.0",
  "id": "\${UniqueId}",
  "packageName": "com.dock.sharepoint.clm",
  "developer": {
      "name": "Dock",
      "websiteUrl": "https://www.mydock365.com",
      "privacyUrl": "https://www.mydock365.com/privacy-policy",
      "termsOfUseUrl": "https://www.mydock365.com/website-usage-terms-and-conditions"
  },
  "icons": {
      "color": "color.png",
      "outline": "outline.png"
  },
  "name": {
      "short": "CMS",
      "full": "Contract Lifecycle Management"
  },
  "description": {
      "short": "Create, manage, and execute contracts effortlessly",
      "full": "Contract lifecycle management offers an effortless contract management platform."
  },
  "accentColor": "#FFFFFF",
  "staticTabs": [
      {
          "entityId": "clmadmin",
          "name": "CLM",
          "contentUrl":"\${origin}/_layouts/15/teamslogon.aspx?SPFX=true&dest=/sites/\${site}/SitePages/Home.aspx",
          "websiteUrl":"\${WebSiteUrl}/SitePages/Home.aspx",
          "scopes": [
              "personal"
          ]
      },
      {
          "entityId": "about",
          "scopes": [
              "personal"
          ]
      }
  ],
  \${BotConfig}
  "permissions": [
      "identity",
      "messageTeamMembers"
  ],
  "validDomains": [
      "\${ValidDomain}"
  ],
  "webApplicationInfo": {
      "id": "\${WebAppId}",
      "resource": "\${Resource}"
  }
}`;


export const teamsAppTemplateV4_3 = (showBotId:any, values:any) => {
  return `{
        "$schema": "https://developer.microsoft.com/en-us/json-schemas/teams/v1.9/MicrosoftTeams.schema.json",
        "manifestVersion": "1.9",
        "version": "1.0.0",
        "id": "\${UniqueId}",
        "packageName": "com.dock.sharepoint.clm",
        "developer": {
            "name": "Dock",
            "websiteUrl": "https://www.mydock365.com",
            "privacyUrl": "https://www.mydock365.com/privacy-policy",
            "termsOfUseUrl": "https://www.mydock365.com/website-usage-terms-and-conditions"
        },
        "icons": {
            "color": "color.png",
            "outline": "outline.png"
        },
        "name": {
            "short": "CMS",
            "full": "Contract Management System"
        },
        "description": {
            "short": "Create, manage, and execute contracts effortlessly",
            "full": "Contract management system offers an effortless contract management platform."
        },
        "accentColor": "#FFFFFF",
        "staticTabs": [
            {
              "entityId": "conversations",
              "scopes": [
                  "personal"
              ]
            },
            {
                "entityId": "clmadmin",
                "name": "CLM",
                "contentUrl":"\${origin}/_layouts/15/teamslogon.aspx?SPFX=true&dest=/sites/\${site}/SitePages/Home.aspx",
                "websiteUrl":"\${WebSiteUrl}/SitePages/Home.aspx",
                "scopes": [
                    "personal"
                ]
            },
            {
                "entityId": "about",
                "scopes": [
                    "personal"
                ]
            }
        ],
        ${showBotId ? `"bots": [
          {
              "botId": "${values.BotId}",
              "scopes": [
                  "personal"
              ],
              "supportsFiles": false,
              "isNotificationOnly": false
          }
        ],` : ""}
        "permissions": [
            "identity",
            "messageTeamMembers"
        ],
        "validDomains": [
            "\${ValidDomain}"
        ],
        "webApplicationInfo": {
            "id": "\${WebAppId}",
            "resource": "\${Resource}"
        }
    }`;
};

export enum BackendVersions {
  "4.0.x" = "4.0.x",
  "4.1.x" = "4.1.x",
  "4.3.x" = "4.3.x",
  "4.4.x" = "4.4.x",
  "4.5.x" = "4.5.x",
  "4.6.x" = "4.6.x",
  "4.7.x" = "4.7.x",
  "4.8.x" = "4.8.x",
  "4.9.x" = "4.9.x",
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  FormControl, FormLabel, Input, FormErrorMessage, InputRightElement, IconButton, InputGroup, Tooltip, Flex, Icon, HStack, Radio, RadioGroup, Switch,
} from "@chakra-ui/react";
import * as Yup from "yup";
import FormHeader from "../../../components/form/FormHeader";
import FormContainer from "../../../components/form/FormContainer";
import FormGrid from "../../../components/form/FormGrid";
import FormFooter from "../../../components/form/FormFooter";
import { generateUniqueGUID } from "../../../helpers/generateUniqueGuid";
import { InfoOutlineIcon, RepeatIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import FloatingButton from "../../../components/FloatingResetButton";
import { getReplacerRegex, onlyUnique, regEx } from "../../../helpers/replacerRegex";
import { backendServiceTemplateV4_7 } from "../../../const/templates/backendServiceTemplate";
import OutputPage from "../../../components/OutputPage";

const Version4_7_xForm: React.FC = () => {
  const [result, setResult] = useState<string>("");
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [showDockerRegistryPassword, setshowDockerRegistryPassword] = useState<boolean>(false);
  const shouldHideField = true;

  const onSubmitForm = useCallback((e: any) => {
    let data = backendServiceTemplateV4_7;
    const placeHolders = [...(data.match(regEx) as RegExpMatchArray)].filter(
      onlyUnique
    );
    const _values: Record<string, any> = {
      ...e,
      SingleOrgAppRegEnabled: e?.SingleOrgAppRegEnabled ? "true" : "false",

    };
    for (const field of placeHolders) {

      if (_values[field]) {

        data = data.replace(getReplacerRegex(field), _values[field]);

      } else {
        data = data.replace(getReplacerRegex(field), "");

      }
    }
    setResult(data);
    setResultVisible(true);
    localStorage.setItem("Version4_7_xFormValues", JSON.stringify(e));
  }, []);

  const {
    errors, touched, values, handleChange, handleBlur, handleSubmit, setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = JSON.parse(localStorage.getItem("Version4_7_xFormValues")!);
    if (data) {
      for (const key of Object.keys(data)) {
        setFieldValue(key, data[key]);
      }
    }
  }, []);

  if (resultVisible) {
    return (
      <OutputPage
        title="Backend Service Config - V4.7.x"
        content={result}
        fileName="Dock-Contract-BackendService-Config.yaml"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Backend Service Config - V4.7.x" />
      <form onSubmit={handleSubmit} onChange={handleChange} onBlur={handleBlur}>
        <FormGrid>
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.ACCESS_CONTROLL_URL && touched.ACCESS_CONTROLL_URL || false}>
              <FormLabel htmlFor="ACCESS_CONTROLL_URL">ACCESS_CONTROLL_URL</FormLabel>
              <Input
                id="ACCESS_CONTROLL_URL"
                name="ACCESS_CONTROLL_URL"
                defaultValue={values.ACCESS_CONTROLL_URL}
                size="sm"
                autoComplete="off"
                placeholder="https://accounts.accesscontrol.windows.net"
              />
              {errors.ACCESS_CONTROLL_URL && <FormErrorMessage>{errors.ACCESS_CONTROLL_URL}</FormErrorMessage>}
            </FormControl>
          )}


          {/* Backend Build ID */}
          <FormControl isInvalid={errors.AI_PORT && touched.AI_PORT || false}>
            <FormLabel htmlFor="AI_PORT">AI Port</FormLabel>
            <Input
              id="AI_PORT"
              name="AI_PORT"
              type="number"
              defaultValue={values.AI_PORT}
              size="sm"
              autoComplete="off"
              required
            />
            {errors.AI_PORT && <FormErrorMessage>{errors.AI_PORT}</FormErrorMessage>}
          </FormControl>

          {/* Authentication Method */}
          <FormControl isInvalid={errors.AUTHENTICATION_METHOD && touched.AUTHENTICATION_METHOD || false}>
            <FormLabel htmlFor="AUTHENTICATION_METHOD">Authentication Method</FormLabel>
            <RadioGroup
              id="AUTHENTICATION_METHOD"
              name="AUTHENTICATION_METHOD"
              onChange={(method) => setFieldValue("AUTHENTICATION_METHOD", method)}
              value={values.AUTHENTICATION_METHOD}
            >
              <HStack spacing={4}>
                <Radio value="JWT">JWT</Radio>
                <Radio value="SESSION">SESSION</Radio>
              </HStack>
            </RadioGroup>
            {errors.AUTHENTICATION_METHOD && <FormErrorMessage>{errors.AUTHENTICATION_METHOD}</FormErrorMessage>}
          </FormControl>

          {/* Base Url */}
          <FormControl isInvalid={errors.BASE_URL && touched.BASE_URL || false}>
            <FormLabel htmlFor="BASE_URL">Base Url</FormLabel>
            <Input
              id="BASE_URL"
              name="BASE_URL"
              defaultValue={values.BASE_URL}
              size="sm"
              autoComplete="off"
              placeholder="https://dockcms-mock.azurewebsites.net"
            />
            {errors.BASE_URL && <FormErrorMessage>{errors.BASE_URL}</FormErrorMessage>}
          </FormControl>



          {shouldHideField ? null : (
            <FormControl isInvalid={errors.CLAUSE_MODEL_PATH && touched.CLAUSE_MODEL_PATH || false}>
              <FormLabel htmlFor="CLAUSE_MODEL_PATH">CLAUSE_MODEL_PATH</FormLabel>
              <Input
                id="CLAUSE_MODEL_PATH"
                name="CLAUSE_MODEL_PATH"
                defaultValue={values.CLAUSE_MODEL_PATH}
                size="sm"
                autoComplete="off"
              />
              {errors.CLAUSE_MODEL_PATH && <FormErrorMessage>{errors.CLAUSE_MODEL_PATH}</FormErrorMessage>}
            </FormControl>
          )}

          {/* Client Id */}
          <FormControl isInvalid={errors.CLIENT_ID && touched.CLIENT_ID || false}>
            <FormLabel htmlFor="CLIENT_ID">Client Id</FormLabel>
            <Input
              id="CLIENT_ID"
              name="CLIENT_ID"
              defaultValue={values.CLIENT_ID}
              size="sm"
              autoComplete="off"
            />
            {errors.CLIENT_ID && <FormErrorMessage>{errors.CLIENT_ID}</FormErrorMessage>}
          </FormControl>


          {shouldHideField ? null : (
            <FormControl isInvalid={errors.DB_CACHE_SYNC_CRON_EXPRESSION && touched.DB_CACHE_SYNC_CRON_EXPRESSION || false}>
              <FormLabel htmlFor="DB_CACHE_SYNC_CRON_EXPRESSION">DB_CACHE_SYNC_CRON_EXPRESSION</FormLabel>
              <Input
                id="DB_CACHE_SYNC_CRON_EXPRESSION"
                name="DB_CACHE_SYNC_CRON_EXPRESSION"
                defaultValue={values.DB_CACHE_SYNC_CRON_EXPRESSION}
                size="sm"
                autoComplete="off"
              />
              {errors.DB_CACHE_SYNC_CRON_EXPRESSION && <FormErrorMessage>{errors.DB_CACHE_SYNC_CRON_EXPRESSION}</FormErrorMessage>}
            </FormControl>
          )}

          {shouldHideField ? null : (
            <FormControl isInvalid={errors.DEFAULT_CONCURRENCY && touched.DEFAULT_CONCURRENCY || false}>
              <FormLabel htmlFor="DEFAULT_CONCURRENCY">DEFAULT_CONCURRENCY</FormLabel>
              <Input
                id="DEFAULT_CONCURRENCY"
                name="DEFAULT_CONCURRENCY"
                defaultValue={values.DEFAULT_CONCURRENCY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.DEFAULT_CONCURRENCY && <FormErrorMessage>{errors.DEFAULT_CONCURRENCY}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.DISABLE_PARALLEL_OCR && touched.DISABLE_PARALLEL_OCR || false}>
              <FormLabel htmlFor="DISABLE_PARALLEL_OCR">DISABLE_PARALLEL_OCR</FormLabel>
              <Input
                id="DISABLE_PARALLEL_OCR"
                name="DISABLE_PARALLEL_OCR"
                defaultValue={values.DISABLE_PARALLEL_OCR}
                size="sm"
                autoComplete="off"
              />
              {errors.DISABLE_PARALLEL_OCR && <FormErrorMessage>{errors.DISABLE_PARALLEL_OCR}</FormErrorMessage>}
            </FormControl>
          )}

          {/* Dock App Secret */}
          <FormControl isInvalid={errors.DOCK_APP_SECRET && touched.DOCK_APP_SECRET || false}>
            <FormLabel htmlFor="DOCK_APP_SECRET">Dock App Secret</FormLabel>
            <Input
              id="DOCK_APP_SECRET"
              name="DOCK_APP_SECRET"
              defaultValue={values.DOCK_APP_SECRET}
              size="sm"
              autoComplete="off"
            />
            {errors.DOCK_APP_SECRET && <FormErrorMessage>{errors.DOCK_APP_SECRET}</FormErrorMessage>}
          </FormControl>

          {shouldHideField ? null : (
            <FormControl isInvalid={errors.DOCKER_REGISTRY_SERVER_PASSWORD && touched.DOCKER_REGISTRY_SERVER_PASSWORD || false}>
              <FormLabel htmlFor="DOCKER_REGISTRY_SERVER_PASSWORD">DOCKER_REGISTRY_SERVER_PASSWORD</FormLabel>
              <InputGroup>
                <Input
                  id="DOCKER_REGISTRY_SERVER_PASSWORD"
                  name="DOCKER_REGISTRY_SERVER_PASSWORD"
                  defaultValue={values.DOCKER_REGISTRY_SERVER_PASSWORD}
                  type={showDockerRegistryPassword ? "text" : "password"}
                  size="sm"
                  autoComplete="off"
                />
                <InputRightElement>
                  <IconButton
                    size="lg"
                    mb={1.5}
                    variant="link"
                    icon={showDockerRegistryPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={() => setshowDockerRegistryPassword(!showDockerRegistryPassword)}
                    aria-label="DOCKER_REGISTRY_SERVER_PASSWORD"
                  />
                </InputRightElement>
              </InputGroup>
              {errors.DOCKER_REGISTRY_SERVER_PASSWORD && <FormErrorMessage>{errors.DOCKER_REGISTRY_SERVER_PASSWORD}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.DOCKER_REGISTRY_SERVER_URL && touched.DOCKER_REGISTRY_SERVER_URL || false}>
              <FormLabel htmlFor="DOCKER_REGISTRY_SERVER_URL">DOCKER_REGISTRY_SERVER_URL</FormLabel>
              <Input
                id="DOCKER_REGISTRY_SERVER_URL"
                name="DOCKER_REGISTRY_SERVER_URL"
                defaultValue={values.DOCKER_REGISTRY_SERVER_URL}
                size="sm"
                autoComplete="off"
                placeholder="https://index.docker.io/v1"
              />
              {errors.DOCKER_REGISTRY_SERVER_URL && <FormErrorMessage>{errors.DOCKER_REGISTRY_SERVER_URL}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.DOCKER_REGISTRY_SERVER_USERNAME && touched.DOCKER_REGISTRY_SERVER_USERNAME || false}>
              <FormLabel htmlFor="DOCKER_REGISTRY_SERVER_USERNAME">DOCKER_REGISTRY_SERVER_USERNAME</FormLabel>
              <Input
                id="DOCKER_REGISTRY_SERVER_USERNAME"
                name="DOCKER_REGISTRY_SERVER_USERNAME"
                defaultValue={values.DOCKER_REGISTRY_SERVER_USERNAME}
                size="sm"
                autoComplete="off"
              />
              {errors.DOCKER_REGISTRY_SERVER_USERNAME && <FormErrorMessage>{errors.DOCKER_REGISTRY_SERVER_USERNAME}</FormErrorMessage>}
            </FormControl>
          )}

          <FormControl isInvalid={errors.DOMAIN && touched.DOMAIN || false}>
            <FormLabel htmlFor="DOMAIN">Domain</FormLabel>
            <Input
              id="DOMAIN"
              name="DOMAIN"
              defaultValue={values.DOMAIN}
              size="sm"
              autoComplete="off"
              placeholder="mysite.sharepoint.com"
            />
            {errors.DOMAIN && <FormErrorMessage>{errors.DOMAIN}</FormErrorMessage>}
          </FormControl>


          <FormControl isInvalid={errors.DOTNET_HOSTNAME && touched.DOTNET_HOSTNAME || false}>
            <FormLabel htmlFor="DOTNET_HOSTNAME">DOTNET_HOSTNAME</FormLabel>
            <Input
              id="DOTNET_HOSTNAME"
              name="DOTNET_HOSTNAME"
              defaultValue={values.DOTNET_HOSTNAME}
              size="sm"
              autoComplete="off"
              placeholder="https://mysite-dotnet.azurewebsites.net"
            />
            {errors.DOTNET_HOSTNAME && <FormErrorMessage>{errors.DOTNET_HOSTNAME}</FormErrorMessage>}
          </FormControl>
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.ENABLE_LOG && touched.ENABLE_LOG || false}>
              <FormLabel htmlFor="ENABLE_LOG">Enable Log</FormLabel>
              <RadioGroup
                id="ENABLE_LOG"
                name="ENABLE_LOG"
                onChange={(method) => setFieldValue("ENABLE_LOG", method)}
                value={values.ENABLE_LOG}
              >
                <HStack spacing={4}>
                  <Radio value="JWT">true</Radio>
                  <Radio value="SESSION">false</Radio>
                </HStack>
              </RadioGroup>
              {errors.ENABLE_LOG && <FormErrorMessage>{errors.ENABLE_LOG}</FormErrorMessage>}
            </FormControl>
          )}

          {/* <FormControl isInvalid={errors.ENABLE_TEST_API && touched.ENABLE_TEST_API || false}>
            <FormLabel htmlFor="ENABLE_TEST_API">Enable Test Api</FormLabel>
            <RadioGroup
              id="ENABLE_TEST_API"
              name="ENABLE_TEST_API"
              onChange={(method) => setFieldValue("ENABLE_TEST_API", method)}
              value={values.ENABLE_TEST_API}
            >
              <HStack spacing={4}>
                <Radio value="JWT">true</Radio>
                <Radio value="SESSION">false</Radio>
              </HStack>
            </RadioGroup>
            {errors.ENABLE_TEST_API && <FormErrorMessage>{errors.ENABLE_TEST_API}</FormErrorMessage>}
          </FormControl> */}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.ENTITY_MODEL_PATH && touched.ENTITY_MODEL_PATH || false}>
              <FormLabel htmlFor="ENTITY_MODEL_PATH">ENTITY_MODEL_PATH</FormLabel>
              <Input
                id="ENTITY_MODEL_PATH"
                name="ENTITY_MODEL_PATH"
                defaultValue={values.ENTITY_MODEL_PATH}
                size="sm"
                autoComplete="off"
              />
              {errors.ENTITY_MODEL_PATH && <FormErrorMessage>{errors.ENTITY_MODEL_PATH}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.FILEUPLOAD_LIMIT_IN_MB && touched.FILEUPLOAD_LIMIT_IN_MB || false}>
              <FormLabel htmlFor="FILEUPLOAD_LIMIT_IN_MB">FILEUPLOAD_LIMIT_IN_MB</FormLabel>
              <Input
                id="FILEUPLOAD_LIMIT_IN_MB"
                name="FILEUPLOAD_LIMIT_IN_MB"
                defaultValue={values.FILEUPLOAD_LIMIT_IN_MB}
                size="sm"
                autoComplete="off"
              />
              {errors.FILEUPLOAD_LIMIT_IN_MB && <FormErrorMessage>{errors.FILEUPLOAD_LIMIT_IN_MB}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.GENERAL_QUEUE_DEFAULT_CONCURRENCY && touched.GENERAL_QUEUE_DEFAULT_CONCURRENCY || false}>
              <FormLabel htmlFor="GENERAL_QUEUE_DEFAULT_CONCURRENCY">GENERAL_QUEUE_DEFAULT_CONCURRENCY</FormLabel>
              <Input
                id="GENERAL_QUEUE_DEFAULT_CONCURRENCY"
                name="GENERAL_QUEUE_DEFAULT_CONCURRENCY"
                defaultValue={values.GENERAL_QUEUE_DEFAULT_CONCURRENCY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.GENERAL_QUEUE_DEFAULT_CONCURRENCY && <FormErrorMessage>{errors.GENERAL_QUEUE_DEFAULT_CONCURRENCY}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.GENERAL_QUEUE_MAX_CONCURRENCY && touched.GENERAL_QUEUE_MAX_CONCURRENCY || false}>
              <FormLabel htmlFor="GENERAL_QUEUE_MAX_CONCURRENCY">GENERAL_QUEUE_MAX_CONCURRENCY</FormLabel>
              <Input
                id="GENERAL_QUEUE_MAX_CONCURRENCY"
                name="GENERAL_QUEUE_MAX_CONCURRENCY"
                defaultValue={values.GENERAL_QUEUE_MAX_CONCURRENCY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.GENERAL_QUEUE_MAX_CONCURRENCY && <FormErrorMessage>{errors.GENERAL_QUEUE_MAX_CONCURRENCY}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.GENERAL_QUEUE_PROCESS_EVERY && touched.GENERAL_QUEUE_PROCESS_EVERY || false}>
              <FormLabel htmlFor="GENERAL_QUEUE_PROCESS_EVERY">GENERAL_QUEUE_PROCESS_EVERY</FormLabel>
              <Input
                id="GENERAL_QUEUE_PROCESS_EVERY"
                name="GENERAL_QUEUE_PROCESS_EVERY"
                defaultValue={values.GENERAL_QUEUE_PROCESS_EVERY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.GENERAL_QUEUE_PROCESS_EVERY && <FormErrorMessage>{errors.GENERAL_QUEUE_PROCESS_EVERY}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.SCHEDULE_QUEUE_DEFAULT_CONCURRENCY && touched.SCHEDULE_QUEUE_DEFAULT_CONCURRENCY || false}>
              <FormLabel htmlFor="SCHEDULE_QUEUE_DEFAULT_CONCURRENCY">SCHEDULE_QUEUE_DEFAULT_CONCURRENCY</FormLabel>
              <Input
                id="SCHEDULE_QUEUE_DEFAULT_CONCURRENCY"
                name="SCHEDULE_QUEUE_DEFAULT_CONCURRENCY"
                defaultValue={values.SCHEDULE_QUEUE_DEFAULT_CONCURRENCY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.SCHEDULE_QUEUE_DEFAULT_CONCURRENCY && <FormErrorMessage>{errors.SCHEDULE_QUEUE_DEFAULT_CONCURRENCY}</FormErrorMessage>}
            </FormControl>
          )}




          {shouldHideField ? null : (
            <FormControl isInvalid={errors.SCHEDULE_QUEUE_MAX_CONCURRENCY && touched.SCHEDULE_QUEUE_MAX_CONCURRENCY || false}>
              <FormLabel htmlFor="SCHEDULE_QUEUE_MAX_CONCURRENCY">SCHEDULE_QUEUE_MAX_CONCURRENCY</FormLabel>
              <Input
                id="SCHEDULE_QUEUE_MAX_CONCURRENCY"
                name="SCHEDULE_QUEUE_MAX_CONCURRENCY"
                defaultValue={values.SCHEDULE_QUEUE_MAX_CONCURRENCY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.SCHEDULE_QUEUE_MAX_CONCURRENCY && <FormErrorMessage>{errors.SCHEDULE_QUEUE_MAX_CONCURRENCY}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.SCHEDULE_QUEUE_PROCESS_EVERY && touched.SCHEDULE_QUEUE_PROCESS_EVERY || false}>
              <FormLabel htmlFor="SCHEDULE_QUEUE_PROCESS_EVERY">SCHEDULE_QUEUE_PROCESS_EVERY</FormLabel>
              <Input
                id="SCHEDULE_QUEUE_PROCESS_EVERY"
                name="SCHEDULE_QUEUE_PROCESS_EVERY"
                defaultValue={values.SCHEDULE_QUEUE_PROCESS_EVERY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.SCHEDULE_QUEUE_PROCESS_EVERY && <FormErrorMessage>{errors.SCHEDULE_QUEUE_PROCESS_EVERY}</FormErrorMessage>}
            </FormControl>
          )}
          {/* Key Vault App Secret Name */}
          <FormControl isInvalid={errors.KEY_VAULT_APP_SECRET_NAME && touched.KEY_VAULT_APP_SECRET_NAME || false}>
            <FormLabel htmlFor="KEY_VAULT_APP_SECRET_NAME">Key Vault App Secret Name</FormLabel>
            <Input
              id="KEY_VAULT_APP_SECRET_NAME"
              name="KEY_VAULT_APP_SECRET_NAME"
              defaultValue={values.KEY_VAULT_APP_SECRET_NAME}
              size="sm"
              autoComplete="off"
            />
            {errors.KEY_VAULT_APP_SECRET_NAME && <FormErrorMessage>{errors.KEY_VAULT_APP_SECRET_NAME}</FormErrorMessage>}
          </FormControl>


          {/* Key Vault Key Name */}
          <FormControl isInvalid={errors.KEY_VAULT_KEY_NAME && touched.KEY_VAULT_KEY_NAME || false}>
            <FormLabel htmlFor="KEY_VAULT_KEY_NAME">Key Vault Key Name</FormLabel>
            <Input
              id="KEY_VAULT_KEY_NAME"
              name="KEY_VAULT_KEY_NAME"
              defaultValue={values.KEY_VAULT_KEY_NAME}
              size="sm"
              autoComplete="off"
            />
            {errors.KEY_VAULT_KEY_NAME && <FormErrorMessage>{errors.KEY_VAULT_KEY_NAME}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={errors.KEY_VAULT_URL && touched.KEY_VAULT_URL || false}>
            <FormLabel htmlFor="KEY_VAULT_URL">Key Vault Url</FormLabel>
            <Input
              id="KEY_VAULT_URL"
              name="KEY_VAULT_URL"
              defaultValue={values.KEY_VAULT_URL}
              size="sm"
              autoComplete="off"
              placeholder="https://mysite.vault.azure.net"
            />
            {errors.KEY_VAULT_URL && <FormErrorMessage>{errors.KEY_VAULT_URL}</FormErrorMessage>}
          </FormControl>

          {/* Log Email */}
          <FormControl isInvalid={errors.LOG_EMAIL && touched.LOG_EMAIL || false}>
            <FormLabel htmlFor="LOG_EMAIL">Log Email</FormLabel>
            <Input
              id="LOG_EMAIL"
              name="LOG_EMAIL"
              defaultValue={values.LOG_EMAIL}
              size="sm"
              autoComplete="off"
            />
            {errors.LOG_EMAIL && <FormErrorMessage>{errors.LOG_EMAIL}</FormErrorMessage>}
          </FormControl>

          {/* Log Level */}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.LOG_LEVEL && touched.LOG_LEVEL || false}>
              <FormLabel htmlFor="LOG_LEVEL">Log Level</FormLabel>
              <Input
                id="LOG_LEVEL"
                name="LOG_LEVEL"
                defaultValue={values.LOG_LEVEL}
                size="sm"
                autoComplete="off"
              />
              {errors.LOG_LEVEL && <FormErrorMessage>{errors.LOG_LEVEL}</FormErrorMessage>}
            </FormControl>
          )}

          {/* Log Password */}
          
          <FormControl isInvalid={errors.LOG_PASSWORD && touched.LOG_PASSWORD || false}>
            <Flex align="center">
              <FormLabel htmlFor="LOG_PASSWORD">Log Password</FormLabel>
              <Tooltip
                label="Each client is provided with a distinct Session Secret and it is not necessary to regenerate it during the upgrade process."
                placement="top-start"
              >
                <Icon as={InfoOutlineIcon} color="gray.400" mb={2} />
              </Tooltip>
            </Flex>
            {errors.LOG_PASSWORD && <FormErrorMessage>{errors.LOG_PASSWORD}</FormErrorMessage>}
            <InputGroup size="md">
              <Input
                id="LOG_PASSWORD"
                name="LOG_PASSWORD"
                defaultValue={values.LOG_PASSWORD}
                size="sm"
                autoComplete="off"
                value={values.LOG_PASSWORD}
                onChange={(e) => setFieldValue("LOG_PASSWORD", e.target.value)}
                placeholder="Generate Log Password"
              />
              <InputRightElement pb={2}>
                <IconButton
                  colorScheme="blue"
                  size="xs"
                  aria-label="Generate"
                  variant="outline"
                  fontWeight="bold"
                  fontSize="md"
                  onClick={() => { setFieldValue("LOG_PASSWORD", generateUniqueGUID().replace(/-/g, "")); }}
                  icon={<RepeatIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          {/* Log Url */}
          <FormControl isInvalid={errors.LOG_PATH && touched.LOG_PATH || false}>
            <FormLabel htmlFor="LOG_PATH">Log Path</FormLabel>
            <Input
              id="LOG_PATH"
              name="LOG_PATH"
              defaultValue={values.LOG_PATH}
              size="sm"
              autoComplete="off"
            />
            {errors.LOG_PATH && <FormErrorMessage>{errors.LOG_PATH}</FormErrorMessage>}
          </FormControl>



          {/* Log Token */}
          <FormControl isInvalid={errors.LOG_TOKEN && touched.LOG_TOKEN || false}>
            <FormLabel htmlFor="LOG_TOKEN">Log Token</FormLabel>
            <Input
              id="LOG_TOKEN"
              name="LOG_TOKEN"
              defaultValue={values.LOG_TOKEN}
              size="sm"
              autoComplete="off"
            />
            {errors.LOG_TOKEN && <FormErrorMessage>{errors.LOG_TOKEN}</FormErrorMessage>}
          </FormControl>
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.MAX_CONCURRENCY && touched.MAX_CONCURRENCY || false}>
              <FormLabel htmlFor="MAX_CONCURRENCY">MAX_CONCURRENCY</FormLabel>
              <Input
                id="MAX_CONCURRENCY"
                name="MAX_CONCURRENCY"
                defaultValue={values.MAX_CONCURRENCY}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.MAX_CONCURRENCY && <FormErrorMessage>{errors.MAX_CONCURRENCY}</FormErrorMessage>}
            </FormControl>
          )}


          {/* Maximum Entity Character Value */}

          {shouldHideField ? null : (
            <FormControl isInvalid={errors.MAX_ENTITY_CHAR && touched.MAX_ENTITY_CHAR || false}>
              <FormLabel htmlFor="MAX_ENTITY_CHAR">MAX_ENTITY_CHAR</FormLabel>
              <Input
                id="MAX_ENTITY_CHAR"
                name="MAX_ENTITY_CHAR"
                defaultValue={values.MAX_ENTITY_CHAR}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.MAX_ENTITY_CHAR && <FormErrorMessage>{errors.MAX_ENTITY_CHAR}</FormErrorMessage>}
            </FormControl>
          )}


          {/* Mongo Uri */}
          <FormControl isInvalid={errors.MONGO_URI && touched.MONGO_URI || false}>
            <FormLabel htmlFor="MONGO_URI">Mongo Uri</FormLabel>
            <Input
              id="MONGO_URI"
              name="MONGO_URI"
              defaultValue={values.MONGO_URI}
              size="sm"
              autoComplete="off"
              placeholder="mongodb://mysite:xxxxx@mysite:12345/mysite"
            />
            {errors.MONGO_URI && <FormErrorMessage>{errors.MONGO_URI}</FormErrorMessage>}
          </FormControl>

          {shouldHideField ? null : (
            <FormControl isInvalid={errors.NODE_ENV && touched.NODE_ENV || false}>
              <FormLabel htmlFor="NODE_ENV">NODE_ENV</FormLabel>
              <Input
                id="NODE_ENV"
                name="NODE_ENV"
                defaultValue={values.NODE_ENV}
                size="sm"
                autoComplete="off"
              />
              {errors.NODE_ENV && <FormErrorMessage>{errors.NODE_ENV}</FormErrorMessage>}
            </FormControl>
          )}



          {/* Notification Cron Expression */}
          <FormControl isInvalid={errors.NOTIFICATION_CRON_EXPRESSION && touched.NOTIFICATION_CRON_EXPRESSION || false}>
            <FormLabel htmlFor="NOTIFICATION_CRON_EXPRESSION">Notification Cron Expression</FormLabel>
            <Input
              id="NOTIFICATION_CRON_EXPRESSION"
              name="NOTIFICATION_CRON_EXPRESSION"
              defaultValue={values.NOTIFICATION_CRON_EXPRESSION}
              size="sm"
              autoComplete="off"
            />
            {errors.NOTIFICATION_CRON_EXPRESSION && <FormErrorMessage>{errors.NOTIFICATION_CRON_EXPRESSION}</FormErrorMessage>}
          </FormControl>


          {/* Origin Url */}
          <FormControl isInvalid={errors.ORIGIN && touched.ORIGIN || false}>
            <FormLabel htmlFor="ORIGIN">Origin</FormLabel>
            <Input
              id="ORIGIN"
              name="ORIGIN"
              defaultValue={values.ORIGIN}
              size="sm"
              autoComplete="off"
              placeholder="https://mydockdev.sharepoint.com"
            />
            {errors.ORIGIN && <FormErrorMessage>{errors.ORIGIN}</FormErrorMessage>}
          </FormControl>
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.PORT && touched.PORT || false}>
              <FormLabel htmlFor="PORT">PORT</FormLabel>
              <Input
                id="PORT"
                name="PORT"
                type="number"
                defaultValue={values.PORT}
                size="sm"
                autoComplete="off"
              />
              {errors.PORT && <FormErrorMessage>{errors.PORT}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.PROCESS_EVERY && touched.PROCESS_EVERY || false}>
              <FormLabel htmlFor="PROCESS_EVERY">PROCESS_EVERY</FormLabel>
              <Input
                id="PROCESS_EVERY"
                name="PROCESS_EVERY"
                type="string"
                defaultValue={values.PROCESS_EVERY}
                size="sm"
                autoComplete="off"
              />
              {errors.PROCESS_EVERY && <FormErrorMessage>{errors.PROCESS_EVERY}</FormErrorMessage>}
            </FormControl>
          )}

          {shouldHideField ? null : (
            <FormControl isInvalid={errors.RESOURCE_ID && touched.RESOURCE_ID || false}>
              <FormLabel htmlFor="RESOURCE_ID">RESOURCE_ID</FormLabel>
              <Input
                id="RESOURCE_ID"
                name="RESOURCE_ID"
                defaultValue={values.RESOURCE_ID}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.RESOURCE_ID && <FormErrorMessage>{errors.RESOURCE_ID}</FormErrorMessage>}
            </FormControl>
          )}


          {/* Session Secret */}
          <FormControl isInvalid={errors.SESSION_SECRET && touched.SESSION_SECRET || false}>
            <Flex align="center">
              <FormLabel htmlFor="SESSION_SECRET">Session Secret</FormLabel>
              <Tooltip
                label="Each client is provided with a distinct Session Secret and it is not necessary to regenerate it during the upgrade process."
                placement="top-start"
              >
                <Icon as={InfoOutlineIcon} color="gray.400" mb={2} />
              </Tooltip>
            </Flex>
            {errors.SESSION_SECRET && <FormErrorMessage>{errors.SESSION_SECRET}</FormErrorMessage>}
            <InputGroup size="md">
              <Input
                id="SESSION_SECRET"
                name="SESSION_SECRET"
                defaultValue={values.SESSION_SECRET}
                size="sm"
                autoComplete="off"
                value={values.SESSION_SECRET}
                onChange={(e) => setFieldValue("SESSION_SECRET", e.target.value)}
                placeholder="Generate Session Secret"
              />
              <InputRightElement pb={2}>
                <IconButton
                  colorScheme="blue"
                  size="xs"
                  aria-label="Generate"
                  variant="outline"
                  fontWeight="bold"
                  fontSize="md"
                  onClick={() => { setFieldValue("SESSION_SECRET", generateUniqueGUID().replace(/-/g, "")); }}
                  icon={<RepeatIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>


          {/* Single Organization App Registration */}
          <FormControl isInvalid={errors.SINGLE_ORG_APP_REG_ENABLED && touched.SINGLE_ORG_APP_REG_ENABLED || false}>
            <FormLabel htmlFor="SINGLE_ORG_APP_REG_ENABLED">Single Org App Enabled</FormLabel>
            <Switch
              id='SINGLE_ORG_APP_REG_ENABLED'
              name="SINGLE_ORG_APP_REG_ENABLED"
              isChecked={values.SINGLE_ORG_APP_REG_ENABLED}
            />
          </FormControl>




        </FormGrid>



        <FormGrid>
          {/* Site Url */}
          <FormControl isInvalid={errors.SITE_URL && touched.SITE_URL || false}>
            <FormLabel htmlFor="SITE_URL">Site Url</FormLabel>
            <Input
              id="SITE_URL"
              name="SITE_URL"
              defaultValue={values.SITE_URL}
              size="sm"
              autoComplete="off"
              placeholder="https://mysite.sharepoint.com/sites/ContractManagement"
            />
            {errors.SITE_URL && <FormErrorMessage>{errors.SITE_URL}</FormErrorMessage>}
          </FormControl>
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.SP_WEBHOOK && touched.SP_WEBHOOK || false}>
              <FormLabel htmlFor="SP_WEBHOOK">SP Webhook</FormLabel>
              <RadioGroup
                id="SP_WEBHOOK"
                name="SP_WEBHOOK"
                onChange={(method) => setFieldValue("SP_WEBHOOK", method)}
                value={values.SP_WEBHOOK}
              >
                <HStack spacing={4}>
                  <Radio value="true">true</Radio>
                  <Radio value="false">false</Radio>
                </HStack>
              </RadioGroup>
              {errors.SP_WEBHOOK && <FormErrorMessage>{errors.SP_WEBHOOK}</FormErrorMessage>}
            </FormControl>
          )}
          {shouldHideField ? null : (
            <FormControl isInvalid={errors.SYNC_DATA_ON_START && touched.SYNC_DATA_ON_START || false}>
              <FormLabel htmlFor="SYNC_DATA_ON_START">Sync Data On Start</FormLabel>
              <RadioGroup
                id="SYNC_DATA_ON_START"
                name="SYNC_DATA_ON_START"
                onChange={(method) => setFieldValue("SYNC_DATA_ON_START", method)}
                value={values.SYNC_DATA_ON_START}
              >
                <HStack spacing={4}>
                  <Radio value="JWT">true</Radio>
                  <Radio value="SESSION">false</Radio>
                </HStack>
              </RadioGroup>
              {errors.SYNC_DATA_ON_START && <FormErrorMessage>{errors.SYNC_DATA_ON_START}</FormErrorMessage>}
            </FormControl>
          )}

          {/* Tenant Id */}
          <FormControl isInvalid={errors.TENANT_ID && touched.TENANT_ID || false}>
            <FormLabel htmlFor="TENANT_ID">Tenant Id</FormLabel>
            <Input
              id="TENANT_ID"
              name="TENANT_ID"
              defaultValue={values.TENANT_ID}
              size="sm"
              autoComplete="off"
            />
            {errors.TENANT_ID && <FormErrorMessage>{errors.TENANT_ID}</FormErrorMessage>}
          </FormControl>



          <FormControl isInvalid={errors.USE_DEFAULT_AI && touched.USE_DEFAULT_AI || false}>
            <FormLabel htmlFor="USE_DEFAULT_AI">Use Default AI</FormLabel>
            <Switch
              id='USE_DEFAULT_AI'
              name="USE_DEFAULT_AI"
              isChecked={values.USE_DEFAULT_AI}
            />
          </FormControl>
          {values.USE_DEFAULT_AI && (
            <>
              <FormControl isInvalid={errors.AI_HOSTNAME && touched.AI_HOSTNAME || false}>
                <FormLabel htmlFor="AI_HOSTNAME">AI Hostname</FormLabel>
                <Input
                  id="AI_HOSTNAME"
                  name="AI_HOSTNAME"
                  defaultValue={values.AI_HOSTNAME}
                  size="sm"
                  autoComplete="off"
                  placeholder="https://dock365-backend-services-qa-ai.azurewebsites.net"
                  required
                />
                {errors.AI_HOSTNAME && <FormErrorMessage>{errors.AI_HOSTNAME}</FormErrorMessage>}
              </FormControl>


              {/* Company Name */}
              <FormControl isInvalid={errors.COMPANY_NAMES && touched.COMPANY_NAMES || false}>
                <FormLabel htmlFor="COMPANY_NAMES">Company Names</FormLabel>
                <Input
                  id="COMPANY_NAMES"
                  name="COMPANY_NAMES"
                  defaultValue={values.COMPANY_NAMES}
                  size="sm"
                  autoComplete="off"
                  placeholder="Company Name|Company Short Name|Company Domain"
                  required
                />
                {errors.COMPANY_NAMES && <FormErrorMessage>{errors.COMPANY_NAMES}</FormErrorMessage>}
              </FormControl>
            </>

          )}

          {/* Sharepoint Authentication method */}
          <FormControl isInvalid={errors.USE_MODERN_AUTH && touched.USE_MODERN_AUTH || false}>
            <FormLabel htmlFor="USE_MODERN_AUTH">Use Modern Auth</FormLabel>
            <Switch
              id='USE_MODERN_AUTH'
              name="USE_MODERN_AUTH"
              isChecked={values.USE_MODERN_AUTH}
            />
          </FormControl>
        </FormGrid>

        {values.USE_MODERN_AUTH && (
          <FormGrid>

            { /* 2️⃣0️⃣ Certificate Name */}
            <FormControl isInvalid={errors.CERTIFICATE_NAME && touched.CERTIFICATE_NAME || false}>
              <FormLabel htmlFor="CERTIFICATE_NAME">Certificate Name</FormLabel>
              <Input
                id="CERTIFICATE_NAME"
                name="CERTIFICATE_NAME"
                defaultValue={values.CERTIFICATE_NAME}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.CERTIFICATE_NAME && <FormErrorMessage>{errors.CERTIFICATE_NAME}</FormErrorMessage>}
            </FormControl>


            { /* 2️⃣1️⃣ Thumb Print */}
            <FormControl isInvalid={errors.THUMB_PRINT && touched.THUMB_PRINT || false}>
              <FormLabel htmlFor="THUMB_PRINT">Thumb Print</FormLabel>
              <Input
                id="THUMB_PRINT"
                name="THUMB_PRINT"
                defaultValue={values.THUMB_PRINT}
                size="sm"
                autoComplete="off"
                required
              />
              {errors.THUMB_PRINT && <FormErrorMessage>{errors.THUMB_PRINT}</FormErrorMessage>}
            </FormControl>

            { /* 2️⃣2️⃣ Authority */}
            <FormControl isInvalid={errors.AUTHORITY && touched.AUTHORITY || false}>
              <FormLabel htmlFor="AUTHORITY">Authority</FormLabel>
              <Input
                id="AUTHORITY"
                name="AUTHORITY"
                defaultValue={values.AUTHORITY}
                size="sm"
                autoComplete="off"
                required
                placeholder="https://login.microsoftonline.com/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              />
              {errors.AUTHORITY && <FormErrorMessage>{errors.AUTHORITY}</FormErrorMessage>}
            </FormControl>


          </FormGrid>

        )}


        <FormControl isInvalid={errors.WEBSITE_TIME_ZONE && touched.WEBSITE_TIME_ZONE || false}
          marginTop="19px" >
          <FormLabel htmlFor="WEBSITE_TIME_ZONE">Website Time Zone</FormLabel>
          <Input
            id="WEBSITE_TIME_ZONE"
            name="WEBSITE_TIME_ZONE"
            defaultValue={values.WEBSITE_TIME_ZONE}
            size="sm"
            autoComplete="off"
          />
          {errors.WEBSITE_TIME_ZONE && <FormErrorMessage>{errors.WEBSITE_TIME_ZONE}</FormErrorMessage>}
        </FormControl>
        {shouldHideField ? null : (
          <FormControl isInvalid={errors.WEBSITES_CONTAINER_START_TIME_LIMIT && touched.WEBSITES_CONTAINER_START_TIME_LIMIT || false}>
            <FormLabel htmlFor="WEBSITES_CONTAINER_START_TIME_LIMIT">WEBSITES_CONTAINER_START_TIME_LIMIT</FormLabel>
            <Input
              id="WEBSITES_CONTAINER_START_TIME_LIMIT"
              name="WEBSITES_CONTAINER_START_TIME_LIMIT"
              defaultValue={values.WEBSITES_CONTAINER_START_TIME_LIMIT}
              size="sm"
              autoComplete="off"
              required
            />
            {errors.WEBSITES_CONTAINER_START_TIME_LIMIT && <FormErrorMessage>{errors.WEBSITES_CONTAINER_START_TIME_LIMIT}</FormErrorMessage>}
          </FormControl>
        )}

        {shouldHideField ? null : (
          <FormControl isInvalid={errors.WEBSITES_ENABLE_APP_SERVICE_STORAGE && touched.WEBSITES_ENABLE_APP_SERVICE_STORAGE || false}
            marginTop="20px">
            <FormLabel htmlFor="WEBSITES_ENABLE_APP_SERVICE_STORAGE">Websites Enable App Service Storage</FormLabel>
            <RadioGroup
              id="WEBSITES_ENABLE_APP_SERVICE_STORAGE"
              name="WEBSITES_ENABLE_APP_SERVICE_STORAGE"
              onChange={(method) => setFieldValue("WEBSITES_ENABLE_APP_SERVICE_STORAGE", method)}
              value={values.WEBSITES_ENABLE_APP_SERVICE_STORAGE}
            >
              <HStack spacing={4}>
                <Radio value="JWT">true</Radio>
                <Radio value="SESSION">false</Radio>
              </HStack>
            </RadioGroup>
            {errors.WEBSITES_ENABLE_APP_SERVICE_STORAGE && <FormErrorMessage>{errors.WEBSITES_ENABLE_APP_SERVICE_STORAGE}</FormErrorMessage>}
          </FormControl>
        )}

        <FormFooter />
      </form>
      <FloatingButton variableName="Version4_7_xFormValues" />
    </FormContainer >
  );
};

export default Version4_7_xForm;

const initialValues = {

  AI_PORT: 443,
  ACCESS_CONTROLL_URL: "https://accounts.accesscontrol.windows.net",
  BASE_URL: "",
  DOCKER_REGISTRY_SERVER_URL: "https://index.docker.io/v1",
  ORIGIN: "",
  SESSION_SECRET: "",
  PORT: 80,
  PROCESS_EVERY: "30 seconds",
  AUTHENTICATION_METHOD: "JWT",
  NOTIFICATION_CRON_EXPRESSION: "30 0  * * *",
  DB_CACHE_SYNC_CRON_EXPRESSION: "0 */2 * * *",
  DISABLE_PARALLEL_OCR: "true",
  SYNC_DATA_ON_START: "false",
  SP_WEBHOOK: "true",
  DOCK_APP_SECRET: "",
  LOG_TOKEN: "",
  AZURE_OCR_API_KEY: "",
  LOG_PATH: "/home/logs",
  ENTITY_MODEL_PATH: "//model-entity-v1//model-best",
  FILEUPLOAD_LIMIT_IN_MB: 26,
  CLAUSE_MODEL_PATH: "//model-clause-v1//model-best",
  LOG_LEVEL: "debug",
  DOCKER_REGISTRY_SERVER_USERNAME: "dock365usa",
  LOG_EMAIL: "support@mydock365.com",
  LOG_PASSWORD: "",
  DOCKER_REGISTRY_SERVER_PASSWORD: "dckr_pat_dtE3DqKsSiGIc4rywZrnNt-Qd-k",
  MONGO_URI: "",
  DOMAIN: "",
  TENANT_ID: "",
  SITE_URL: "",
  CLIENT_ID: "",
  SINGLE_ORG_APP_REG_ENABLED: false,
  USE_MODERN_AUTH: false,
  USE_DEFAULT_AI: true,
  CERTIFICATE_NAME: "",
  THUMB_PRINT: "",
  AUTHORITY: "",
  ENABLE_LOG: "true",
  // ENABLE_TEST_API: "true",
  WEBSITES_ENABLE_APP_SERVICE_STORAGE: "false",
  KEY_VAULT_URL: "",
  NODE_ENV: "production",
  KEY_VAULT_KEY_NAME: "RSAKey",
  WEBSITE_TIME_ZONE: "",
  KEY_VAULT_APP_SECRET_NAME: "AppSecret",
  AI_HOSTNAME: "",
  COMPANY_NAMES: "",
  MAX_ENTITY_CHAR: 100000,
  DEFAULT_CONCURRENCY: 1,
  MAX_CONCURRENCY: 3,
  GENERAL_QUEUE_DEFAULT_CONCURRENCY: 2,
  GENERAL_QUEUE_MAX_CONCURRENCY: 5,
  GENERAL_QUEUE_PROCESS_EVERY: "30 seconds",
  SCHEDULE_QUEUE_DEFAULT_CONCURRENCY: 1,
  SCHEDULE_QUEUE_MAX_CONCURRENCY: 1,
  SCHEDULE_QUEUE_PROCESS_EVERY: "30 seconds",
  RESOURCE_ID: "00000003-0000-0ff1-ce00-000000000000",
  WEBSITES_CONTAINER_START_TIME_LIMIT: 1800,
  DOTNET_HOSTNAME: "",
};

const validationSchema = Yup.object().shape({
  AI_PORT: Yup.number().required("Required"),
  FILEUPLOAD_LIMIT_IN_MB: Yup.number().required("Required"),
  PORT: Yup.number().required("Required"),
  ACCESS_CONTROLL_URL: Yup.string().url("Enter a valid URL").required("Required"),
  BASE_URL: Yup.string().url("Enter a valid URL").required("Required"),
  DOCKER_REGISTRY_SERVER_URL: Yup.string().url("Enter a valid URL").required("Required"),
  ORIGIN: Yup.string().url("Enter a valid URL").required("Required"),
  SESSION_SECRET: Yup.string().length(32, "Must be 32 characters"),
  AUTHENTICATION_METHOD: Yup.string().required("Required"),
  SP_WEBHOOK: Yup.string().required("Required"),
  ENABLE_LOG: Yup.string().required("Required"),
  // ENABLE_TEST_API: Yup.string().required("Required"),
  NOTIFICATION_CRON_EXPRESSION: Yup.string().test("NotificationCron", "Invalid cron expression", (value) => {
    if (!value) {
      return true;
    }
    return cronExpressionRegex.test(value);
  }).required("Required"),
  DB_CACHE_SYNC_CRON_EXPRESSION: Yup.string().test("NotificationCron", "Invalid cron expression", (value) => {
    if (!value) {
      return true;
    }
    return cronExpressionRegex.test(value);
  }).required("Required"),
  DOCK_APP_SECRET: Yup.string().required("Required"),
  LOG_TOKEN: Yup.string().required("Required"),
  LOG_PATH: Yup.string().required("Required"),
  ENTITY_MODEL_PATH: Yup.string().required("Required"),
  LOG_LEVEL: Yup.string().required("Required"),
  LOG_EMAIL: Yup.string().email("Enter a valid email"),
  LOG_PASSWORD: Yup.string().required("Required"),
  SYNC_DATA_ON_START: Yup.string().required("Required"),
  DOCKER_REGISTRY_SERVER_PASSWORD: Yup.string().required("Required"),
  MONGO_URI: Yup.string().required("Required"),
  RESOURCE_ID: Yup.string().required("Required"),
  DOMAIN: Yup.string().required("Required").matches(/^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/, "Not a valid domain"),
  TENANT_ID: Yup.string().required("Required"),
  SITE_URL: Yup.string().url("Enter a valid URL").required("Required"),
  CLIENT_ID: Yup.string().required("Required"),
  PROCESS_EVERY: Yup.string().required("Required"),
  SINGLE_ORG_APP_REG_ENABLED: Yup.boolean(),
  DISABLE_PARALLEL_OCR: Yup.boolean(),
  USE_MODERN_AUTH: Yup.boolean(),
  USE_DEFAULT_AI: Yup.boolean(),
  CERTIFICATE_NAME: Yup.string(),
  DOCKER_REGISTRY_SERVER_USERNAME: Yup.string(),
  THUMB_PRINT: Yup.string(),
  AUTHORITY: Yup.string().url("Enter a valid URL"),
  NODE_ENV: Yup.string().required("Required"),
  KEY_VAULT_URL: Yup.string().url("Enter a valid URL").required("Required"),
  KEY_VAULT_KEY_NAME: Yup.string().required("Required"),
  WEBSITE_TIME_ZONE: Yup.string().required("Required"),
  KEY_VAULT_APP_SECRET_NAME: Yup.string().required("Required"),
  AiEnabled: Yup.boolean(),
  AI_HOSTNAME: Yup.string().url("Enter a valid URL"),
  COMPANY_NAMES: Yup.string(),
  MAX_ENTITY_CHAR: Yup.number(),
  MAX_CONCURRENCY: Yup.number(),
  DEFAULT_CONCURRENCY: Yup.number(),
  WEBSITES_CONTAINER_START_TIME_LIMIT: Yup.number(),
  DOTNET_HOSTNAME: Yup.string().url("Enter a valid URL").required("Required"),
});


const cronExpressionRegex = /^(\*|\d{1,2}|\d{1,2}(-\d{1,2})?)(\/\d{1,2})?(\s+(\*|\d{1,2}|\d{1,2}(-\d{1,2})?)(\/\d{1,2})?){4}$/;


/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useCallback, useContext, useState } from "react";
import {
  Box, Button, Flex, FormControl, FormErrorMessage,
  FormLabel, Grid, GridItem, Input, Tooltip, Switch
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import OutputPage from "../../../components/OutputPage";
import { backendServiceTemplateV4_0 } from "../../../const/templates/backendServiceTemplate";
import { regEx, onlyUnique, getReplacerRegex } from "../../../helpers/replacerRegex";
import { AppContext } from "../../../AppProvider";
import FormHeader from "../../../components/form/FormHeader";
import FormContainer from "../../../components/form/FormContainer";

const Version4_0_xForm = () => {
  const [values, setValues] = useState({
    BuildId: undefined,
    Origin: "",
    BaseUrl: "",
    SiteUrl: "",
    ClientId: "",
    ClientSecret: "",
    DocusignSecret: "",
    DocusignIntegrationKey: "",
    DocusignAccountId: "",
    DocusignConnectSecret: "",
    SertifyApiDomain: "",
    SertifyApiKey: "",
    SertifyWebhookToken: "",
  });
  const [DocusignEnabled, setDocusignEnabled] = useState(false);
  const [SertifyEnabled, setSertifyEnabled] = useState(false);
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isSmall } = useContext(AppContext);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onChangeDocusignEnabled = useCallback(() => {
    setDocusignEnabled(!DocusignEnabled);
    if (!DocusignEnabled === false) {
      setValues((prevState) => ({
        ...prevState,
        DocusignSecret: "",
        DocusignIntegrationKey: "",
        DocusignAccountId: "",
        DocusignConnectSecret: "",
      }));
    }
  }, [DocusignEnabled]);

  const onChangeSertifyEnabled = useCallback(() => {
    setSertifyEnabled(!SertifyEnabled);
    if (!DocusignEnabled === false) {
      setValues((prevState) => ({
        ...prevState,
        SertifyApiDomain: "",
        SertifyApiKey: "",
        SertifyWebhookToken: "",
      }));
    }
  }, [SertifyEnabled]);


  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data = backendServiceTemplateV4_0;
    const placeHolders = [...data.match(regEx) as RegExpMatchArray].filter(onlyUnique);
    const _values: Record<string, any> = values;
    for (const field of placeHolders) {
      if (_values[field]) {
        data = data.replace(getReplacerRegex(field), _values[field]);
      } else {
        data = data.replace(getReplacerRegex(field), "");
      }
    }
    setLoading(false);
    setResult(data);
    setResultVisible(true);
  }, [values]);

  //⛔ Validate Required fields To enable submit button
  const isError = !values.BuildId || !values.Origin.trim().length || !values.BaseUrl.trim().length ||
    !values.SiteUrl.trim().length || !values.ClientId.trim().length || !values.ClientSecret.trim().length || (DocusignEnabled && !values.DocusignSecret.trim().length) ||
    (DocusignEnabled && !values.DocusignIntegrationKey.trim().length) || (DocusignEnabled && !values.DocusignAccountId.trim().length) || (DocusignEnabled && !values.DocusignConnectSecret.trim().length) ||
    (SertifyEnabled && !values.SertifyApiDomain.trim().length) || (SertifyEnabled && !values.SertifyApiKey.trim().length) || (SertifyEnabled && !values.SertifyWebhookToken.trim().length);

  if (resultVisible) {
    return (
      <OutputPage
        title="Backend Service Config - V4.0.x"
        content={result}
        fileName="Dock-Contract-BackendService-Config.yaml"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Backend Service Config - V4.0.x" />

      <form onSubmit={onSubmit} >
        <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.BuildId}>
              <FormLabel htmlFor="BuildId">Backend Build ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="BuildId"
                  name="BuildId"
                  type="number"
                  value={values.BuildId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.BuildId && <FormErrorMessage>Backend Build ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.Origin.trim().length}>
              <FormLabel htmlFor="Origin">Origin Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="Origin"
                  name="Origin"
                  value={values.Origin}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.Origin.trim().length && <FormErrorMessage>Origin Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.BaseUrl.trim().length}>
              <FormLabel htmlFor="BaseUrl">Base Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="BaseUrl"
                  name="BaseUrl"
                  value={values.BaseUrl}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.BaseUrl.trim().length && <FormErrorMessage>Base Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.SiteUrl.trim().length}>
              <FormLabel htmlFor="SiteUrl">Site Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="SiteUrl"
                  name="SiteUrl"
                  value={values.SiteUrl}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.SiteUrl.trim().length && <FormErrorMessage>Site Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.ClientId.trim().length}>
              <FormLabel htmlFor="ClientId">Client ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="ClientId"
                  name="ClientId"
                  value={values.ClientId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ClientId.trim().length && <FormErrorMessage>Client ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.ClientSecret.trim().length}>
              <FormLabel htmlFor="ClientSecret">Client Secret</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="ClientSecret"
                  name="ClientSecret"
                  value={values.ClientSecret}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ClientSecret.trim().length && <FormErrorMessage>Client Secret is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>
        </Grid>

        {/*⛔ Docusign Fields */}
        <FormControl display='flex' alignItems='center' mt={10} py={2} gap="2">
          <Switch id='DocusignEnabled' name="DocusignEnabled" onChange={onChangeDocusignEnabled} />
          <FormLabel htmlFor='DocusignEnabled' mb='0'>
            Enable Docusign
          </FormLabel>
        </FormControl>
        {DocusignEnabled &&
          <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.DocusignSecret.trim().length}>
                <FormLabel htmlFor="DocusignSecret">Secret Key</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="DocusignSecret"
                    name="DocusignSecret"
                    value={values.DocusignSecret}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.DocusignSecret.trim().length && <FormErrorMessage>Secret Key is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.DocusignIntegrationKey.trim().length}>
                <FormLabel htmlFor="DocusignIntegrationKey">Integration Key</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="DocusignIntegrationKey"
                    name="DocusignIntegrationKey"
                    value={values.DocusignIntegrationKey}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.DocusignIntegrationKey.trim().length && <FormErrorMessage>Integration Key is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.DocusignAccountId.trim().length}>
                <FormLabel htmlFor="DocusignAccountId">Account Id</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="DocusignAccountId"
                    name="DocusignAccountId"
                    value={values.DocusignAccountId}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.DocusignAccountId.trim().length && <FormErrorMessage>Account Id is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.DocusignConnectSecret.trim().length}>
                <FormLabel htmlFor="DocusignConnectSecret">Connect Secret</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="DocusignConnectSecret"
                    name="DocusignConnectSecret"
                    value={values.DocusignConnectSecret}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.DocusignConnectSecret.trim().length && <FormErrorMessage>Connect Secret is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

          </Grid>
        }

        {/*⛔ Sertify Fields */}
        <FormControl display='flex' alignItems='center' mt={10} py={2} gap="2">
          <Switch id='SertifyEnabled' name="SertifyEnabled" onChange={onChangeSertifyEnabled} />
          <FormLabel htmlFor='SertifyEnabled' mb='0'>
            Enable Sertify
          </FormLabel>
        </FormControl>
        {SertifyEnabled &&
          <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.SertifyApiDomain.trim().length}>
                <FormLabel htmlFor="SertifyApiDomain">Api Domain</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="SertifyApiDomain"
                    name="SertifyApiDomain"
                    value={values.SertifyApiDomain}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.SertifyApiDomain.trim().length && <FormErrorMessage>Api Domain is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.SertifyApiKey.trim().length}>
                <FormLabel htmlFor="SertifyApiKey">Api Key</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="SertifyApiKey"
                    name="SertifyApiKey"
                    value={values.SertifyApiKey}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.SertifyApiKey.trim().length && <FormErrorMessage>Api Key is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>

            <GridItem h='10' my={4}>
              <FormControl isInvalid={!values.SertifyWebhookToken.trim().length}>
                <FormLabel htmlFor="SertifyWebhookToken">Webhook Token</FormLabel>
                <Tooltip label='' placement='top-end'>
                  <Input
                    id="SertifyWebhookToken"
                    name="SertifyWebhookToken"
                    value={values.SertifyWebhookToken}
                    onChange={onChange}
                    size="sm"
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.SertifyWebhookToken.trim().length && <FormErrorMessage>Webhook Token is required.</FormErrorMessage>}
              </FormControl>
            </GridItem>
          </Grid>
        }

        {/*⛔ Footer Buttons */}
        <Box>
          <Flex justifyContent={isSmall ? "center" : "flex-end"} gap={5} my={10}>
            <Button
              mt={4}
              variant="outline"
              colorScheme='facebook'
              width={isSmall ? "45%" : "32"}
              disabled={loading}
              onClick={() => { navigate("/"); }}>
              Back
            </Button>
            <Button size='md'
              colorScheme='facebook'
              type="submit" mt={4}
              width={isSmall ? "45%" : "32"}
              isDisabled={isError}>
              Create
            </Button>
          </Flex>
        </Box>
      </form>
    </FormContainer >
  );
};

export default Version4_0_xForm;
export const outlookAddInTemplate = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<OfficeApp xmlns="http://schemas.microsoft.com/office/appforoffice/1.1"
  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
  xmlns:bt="http://schemas.microsoft.com/office/officeappbasictypes/1.0"
  xmlns:mailappor="http://schemas.microsoft.com/office/mailappversionoverrides/1.0" xsi:type="MailApp">
  <Id>628e4e12-23e3-4446-ab7f-90261bc7a336</Id>
  <Version>\${Version}</Version>
  <ProviderName>Dock365</ProviderName>
  <DefaultLocale>en-US</DefaultLocale>
  <DisplayName DefaultValue="Dock CMS"/>
  <Description DefaultValue="A Dock Outlook Add-in to upload and attach CMS documents."/>
  <IconUrl DefaultValue="https://\${BackendDomain}/images/dockIcon_32x32.png"/>
  <HighResolutionIconUrl DefaultValue="https://\${BackendDomain}/images/dockIcon_80x80.png"/>
  <SupportUrl DefaultValue="https://docs.microsoft.com/office/dev/add-ins/overview/office-add-in-code-samples"/>
  <AppDomains>
    <AppDomain>https://www.contoso.com</AppDomain>
  </AppDomains>
  <Hosts>
    <Host Name="Mailbox"/>
  </Hosts>
  <Requirements>
    <Sets>
      <Set Name="Mailbox" MinVersion="1.1"/>
    </Sets>
  </Requirements>
  <FormSettings>
    <Form xsi:type="ItemRead">
      <DesktopSettings>
        <SourceLocation DefaultValue="https://\${BackendDomain}/outlook-add-in/upload/home"/>
        <RequestedHeight>250</RequestedHeight>
      </DesktopSettings>
    </Form>
  </FormSettings>
  <Permissions>ReadWriteItem</Permissions>
  <Rule xsi:type="RuleCollection" Mode="Or">
    <Rule xsi:type="ItemIs" ItemType="Appointment" FormType="Edit"/>
    <Rule xsi:type="ItemIs" ItemType="Message" FormType="Edit"/>
  </Rule>
  <DisableEntityHighlighting>false</DisableEntityHighlighting>
  <VersionOverrides xmlns="http://schemas.microsoft.com/office/mailappversionoverrides" xsi:type="VersionOverridesV1_0">
    <Requirements>
      <bt:Sets DefaultMinVersion="1.5">
        <bt:Set Name="Mailbox" />
      </bt:Sets>
    </Requirements>
    <Hosts>
      <Host xsi:type="MailHost">
        <DesktopFormFactor>
            <!-- Message Read -->
            <ExtensionPoint xsi:type="MessageReadCommandSurface">
            <!-- Use the default tab of the ExtensionPoint -->
            <OfficeTab id="TabDefault">
              <Group id="msgReadGroup">
                <Label resid="CommandsGroup.Label"/>                  
                <Control xsi:type="Menu" id="Dock.Read.Menu">
                  <Label resid="Dropdown.Label" />
                  <Supertip>
                    <Title resid="Dropdown.Label" />
                    <Description resid="Dropdown.Label.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Icon.16x16"/>
                    <bt:Image size="32" resid="Icon.32x32"/>
                    <bt:Image size="80" resid="Icon.80x80"/>
                  </Icon>
                  <Items>
                    <Item id="Contoso.Read.Menu.Item1">
                      <Label resid="TPButton.Upload.Label"/>
                      <Supertip>
                        <Title resid="TPButton.Upload.SupertipTitle" />
                        <Description resid="TPButton.Upload.SupertipText" />
                      </Supertip>
                      <Icon>
                        <bt:Image size="16" resid="Icon.16x16"/>
                        <bt:Image size="32" resid="Icon.32x32"/>
                        <bt:Image size="80" resid="Icon.80x80"/>
                      </Icon>
                      <Action xsi:type="ShowTaskpane">
                        <SourceLocation resid="TP.Upload.Url" />
                      </Action>
                    </Item>        
                  </Items>
                </Control>
                <!-- Go to http://aka.ms/ButtonCommands to learn how to add more Controls: ExecuteFunction and Menu -->
              </Group>
            </OfficeTab>
          </ExtensionPoint>            
          <!-- Message Compose -->
          <ExtensionPoint xsi:type="MessageComposeCommandSurface">
            <!-- Use the default tab of the ExtensionPoint -->
            <OfficeTab id="TabDefault">
              <Group id="msgComposeGroup">
                <Label resid="CommandsGroup.Label"/>                  
                <Control xsi:type="Menu" id="Dock.Compose.Menu">
                  <Label resid="Dropdown.Label" />
                  <Supertip>
                    <Title resid="Dropdown.Label" />
                    <Description resid="Dropdown.Label.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Icon.16x16"/>
                    <bt:Image size="32" resid="Icon.32x32"/>
                    <bt:Image size="80" resid="Icon.80x80"/>
                  </Icon>
                  <Items>
                    <Item id="Dock.Compose.Menu.Item2">
                      <Label resid="TPButton.Attach.Label"/>
                      <Supertip>
                        <Title resid="TPButton.Attach.SupertipTitle" />
                        <Description resid="TPButton.Attach.SupertipText" />
                      </Supertip>
                      <Icon>
                        <bt:Image size="16" resid="Icon.16x16"/>
                        <bt:Image size="32" resid="Icon.32x32"/>
                        <bt:Image size="80" resid="Icon.80x80"/>
                      </Icon>
                      <Action xsi:type="ShowTaskpane">
                        <SourceLocation resid="TP.Attach.Url" />
                      </Action>
                    </Item>
                  
                  </Items>
                </Control>
                <!-- Go to http://aka.ms/ButtonCommands to learn how to add more Controls: ExecuteFunction and Menu -->
              </Group>
            </OfficeTab>
          </ExtensionPoint>

          </DesktopFormFactor>
      </Host>
    </Hosts>

    
    <Resources>
      <bt:Images>
        <bt:Image id="Icon.16x16" DefaultValue="https://\${BackendDomain}/images/dockIcon_16x16.png"/>
        <bt:Image id="Icon.32x32" DefaultValue="https://\${BackendDomain}/images/dockIcon_32x32.png"/>
        <bt:Image id="Icon.80x80" DefaultValue="https://\${BackendDomain}/images/dockIcon_80x80.png"/>          
        <bt:Image id="Icon.Attach.16x16" DefaultValue="https://\${BackendDomain}/images/icons/attach_16x16.png"/>
        <bt:Image id="Icon.Attach.32x32" DefaultValue="https://\${BackendDomain}/images/icons/attach_32x32.png"/>
        <bt:Image id="Icon.Attach.80x80" DefaultValue="https://\${BackendDomain}/images/icons/attach_80x80.png"/>
        <bt:Image id="Icon.Upload.16x16" DefaultValue="https://\${BackendDomain}/images/icons/upload_16x16.png"/>
        <bt:Image id="Icon.Upload.32x32" DefaultValue="https://\${BackendDomain}/images/icons/upload_32x32.png"/>
        <bt:Image id="Icon.Upload.80x80" DefaultValue="https://\${BackendDomain}/images/icons/upload_80x80.png"/>
      </bt:Images>
      <bt:Urls>
        <bt:Url id="TP.Upload.Url" DefaultValue="https://\${BackendDomain}/outlook-add-in/upload/home"/>
        <bt:Url id="TP.Attach.Url" DefaultValue="https://\${BackendDomain}/outlook-add-in/attach/home"/>
      </bt:Urls>
      <bt:ShortStrings>
        <bt:String id="CommandsGroup.Label" DefaultValue="Dock"/>          
        <bt:String id="Dropdown.Label" DefaultValue="Contract" />
        <bt:String id="TPButton.Upload.Label" DefaultValue="Upload to CMS"/>
        <bt:String id="TPButton.Upload.SupertipTitle" DefaultValue="Upload attachments to contract documents"/>
        <bt:String id="TPButton.Attach.Label" DefaultValue="Attach from CMS"/>
        <bt:String id="TPButton.Attach.SupertipTitle" DefaultValue="Attach from contract documents"/>
      </bt:ShortStrings>
      <bt:LongStrings>
        <bt:String id="TPButton.Upload.SupertipText" DefaultValue="Upload the mail attachment to contract documents"/>
        <bt:String id="TPButton.Attach.SupertipText" DefaultValue="Add attachments from contract documents"/>
        <bt:String id="Dropdown.Label.Tooltip" DefaultValue="Click to Show Options of Dock CMS" />
      </bt:LongStrings>
    </Resources> 

    <!-- VersionOverrides v1.1 to add support for SSO -->
    <VersionOverrides xmlns="http://schemas.microsoft.com/office/mailappversionoverrides/1.1"
     xsi:type="VersionOverridesV1_1">
      <Requirements>
        <bt:Sets DefaultMinVersion="1.5">
          <bt:Set Name="Mailbox" />
        </bt:Sets>
      </Requirements>
      <Hosts>
        <Host xsi:type="MailHost">

          <DesktopFormFactor>
            <!-- Message Read -->
            <ExtensionPoint xsi:type="MessageReadCommandSurface">
            <!-- Use the default tab of the ExtensionPoint -->
            <OfficeTab id="TabDefault">
              <Group id="msgReadGroup">
                <Label resid="CommandsGroup.Label"/>                  
                <Control xsi:type="Menu" id="Dock.Read.Menu">
                  <Label resid="Dropdown.Label" />
                  <Supertip>
                    <Title resid="Dropdown.Label" />
                    <Description resid="Dropdown.Label.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Icon.16x16"/>
                    <bt:Image size="32" resid="Icon.32x32"/>
                    <bt:Image size="80" resid="Icon.80x80"/>
                  </Icon>
                  <Items>
                    <Item id="Contoso.Read.Menu.Item1">
                      <Label resid="TPButton.Upload.Label"/>
                      <Supertip>
                        <Title resid="TPButton.Upload.SupertipTitle" />
                        <Description resid="TPButton.Upload.SupertipText" />
                      </Supertip>
                      <Icon>
                        <bt:Image size="16" resid="Icon.Upload.16x16"/>
                        <bt:Image size="32" resid="Icon.Upload.32x32"/>
                        <bt:Image size="80" resid="Icon.Upload.80x80"/>
                      </Icon>
                      <Action xsi:type="ShowTaskpane">
                        <SourceLocation resid="TP.Upload.Url" />
                      </Action>
                    </Item>                  
                  </Items>
                </Control>
                <!-- Go to http://aka.ms/ButtonCommands to learn how to add more Controls: ExecuteFunction and Menu -->
              </Group>
            </OfficeTab>
          </ExtensionPoint>            
          <!-- Message Compose -->
          <ExtensionPoint xsi:type="MessageComposeCommandSurface">
            <!-- Use the default tab of the ExtensionPoint -->
            <OfficeTab id="TabDefault">
              <Group id="msgComposeGroup">
                <Label resid="CommandsGroup.Label"/>                  
                <Control xsi:type="Menu" id="Dock.Compose.Menu">
                  <Label resid="Dropdown.Label" />
                  <Supertip>
                    <Title resid="Dropdown.Label" />
                    <Description resid="Dropdown.Label.Tooltip" />
                  </Supertip>
                  <Icon>
                    <bt:Image size="16" resid="Icon.16x16"/>
                    <bt:Image size="32" resid="Icon.32x32"/>
                    <bt:Image size="80" resid="Icon.80x80"/>
                  </Icon>
                  <Items>
                    <Item id="Dock.Compose.Menu.Item2">
                      <Label resid="TPButton.Attach.Label"/>
                      <Supertip>
                        <Title resid="TPButton.Attach.SupertipTitle" />
                        <Description resid="TPButton.Attach.SupertipText" />
                      </Supertip>
                      <Icon>
                        <bt:Image size="16" resid="Icon.Attach.16x16"/>
                        <bt:Image size="32" resid="Icon.Attach.32x32"/>
                        <bt:Image size="80" resid="Icon.Attach.80x80"/>
                      </Icon>
                      <Action xsi:type="ShowTaskpane">
                        <SourceLocation resid="TP.Attach.Url" />
                      </Action>
                    </Item>
                  
                  </Items>
                </Control>
                <!-- Go to http://aka.ms/ButtonCommands to learn how to add more Controls: ExecuteFunction and Menu -->
              </Group>
            </OfficeTab>
          </ExtensionPoint>

          </DesktopFormFactor>
        </Host>
      </Hosts>

      <Resources>
        <bt:Images>
          <bt:Image id="Icon.16x16" DefaultValue="https://\${BackendDomain}/images/dockIcon_16x16.png"/>
          <bt:Image id="Icon.32x32" DefaultValue="https://\${BackendDomain}/images/dockIcon_32x32.png"/>
          <bt:Image id="Icon.80x80" DefaultValue="https://\${BackendDomain}/images/dockIcon_80x80.png"/>          
          <bt:Image id="Icon.Attach.16x16" DefaultValue="https://\${BackendDomain}/images/icons/attach_16x16.png"/>
          <bt:Image id="Icon.Attach.32x32" DefaultValue="https://\${BackendDomain}/images/icons/attach_32x32.png"/>
          <bt:Image id="Icon.Attach.80x80" DefaultValue="https://\${BackendDomain}/images/icons/attach_80x80.png"/>
          <bt:Image id="Icon.Upload.16x16" DefaultValue="https://\${BackendDomain}/images/icons/upload_16x16.png"/>
          <bt:Image id="Icon.Upload.32x32" DefaultValue="https://\${BackendDomain}/images/icons/upload_32x32.png"/>
          <bt:Image id="Icon.Upload.80x80" DefaultValue="https://\${BackendDomain}/images/icons/upload_80x80.png"/>
        </bt:Images>
        <bt:Urls>
          <bt:Url id="TP.Upload.Url" DefaultValue="https://\${BackendDomain}/outlook-add-in/upload/home"/>
          <bt:Url id="TP.Attach.Url" DefaultValue="https://\${BackendDomain}/outlook-add-in/attach/home"/>       
        </bt:Urls>
        <bt:ShortStrings>
          <bt:String id="CommandsGroup.Label" DefaultValue="Dock"/>          
          <bt:String id="Dropdown.Label" DefaultValue="Contract" />
          <bt:String id="TPButton.Upload.Label" DefaultValue="Upload to CMS"/>
          <bt:String id="TPButton.Upload.SupertipTitle" DefaultValue="Upload attachments to contract documents"/>
          <bt:String id="TPButton.Attach.Label" DefaultValue="Attach from CMS"/>
          <bt:String id="TPButton.Attach.SupertipTitle" DefaultValue="Attach from contract documents"/>

        </bt:ShortStrings>
        <bt:LongStrings>
          <bt:String id="TPButton.Upload.SupertipText" DefaultValue="Upload the mail attachment to contract documents"/>
          <bt:String id="TPButton.Attach.SupertipText" DefaultValue="Add attachments from contract documents"/>
          <bt:String id="Dropdown.Label.Tooltip" DefaultValue="Click to Show Options of Dock CMS" />
        </bt:LongStrings>
      </Resources>   
      <WebApplicationInfo>  
          <Id>\${ApplicationId}</Id>
          <Resource>api://\${BackendDomain}/\${ApplicationId}</Resource>
          <Scopes>  
            <Scope>openid</Scope>
            <Scope>profile</Scope>
            <Scope>offline_access</Scope>
            <Scope>files.readwrite</Scope>
            <Scope>mail.read</Scope>
          </Scopes>  
      </WebApplicationInfo> 
    </VersionOverrides>    
  </VersionOverrides>
</OfficeApp>
`;
export const menuItems = [
  {
    title: "Backend Service",
    path: "/backend-service",
    iconPath: "https://cdn.worldvectorlogo.com/logos/azure-2.svg",
    description: "Backend service is a part of Dock365 CLM that is used to manage " +
      "integrations like DocuSign, external vendor portal, and also bulk actions.",
  },
  {
    title: "Teams App and Bot",
    path: "/teams-app",
    iconPath: "https://cdn.worldvectorlogo.com/logos/microsoft-teams-1.svg",
    description: "Teams App is an integration of Dock365 CLM in Microsoft Teams " +
      "for receiving notifications through chat bot and quick access of CLM.",
  },
  {
    title: "Word Add-In",
    path: "/word-addIn",
    iconPath: "https://cdn.worldvectorlogo.com/logos/word-1.svg",
    description: "The Dock 365 CLM Word add-in feature enables the user to easily manage " +
      "contract templates from the Word desktop app or online.",
  },
  {
    title: "Outlook Add-In",
    path: "/outlook-addIn",
    iconPath: "https://cdn.worldvectorlogo.com/logos/outlook-1.svg",
    description: "The Dock 365 CLM Outlook add-in feature enables the user to attach document from CLM " +
      "and upload attachment from email to CLM.",
  },
];

import React, { useContext } from "react";
import { Select, useBreakpointValue } from "@chakra-ui/react";
import { BackendVersions } from "../../const/backendVersions";
import { AppContext } from "../../AppProvider";

const Dropdown = () => {
  const { onVersionChange, selectedVersion } = useContext(AppContext);
  const isSmall = useBreakpointValue({ base: true, md: false });

  return (
    <Select
      bg='whiteAlpha.900'
      rounded="lg"
      fontWeight="semibold"
      color="facebook.800"
      value={selectedVersion}
      size={isSmall ? "sm" : "md"}
      onChange={(e) => onVersionChange && onVersionChange(e.target.value as BackendVersions)}
    >
      {Object.values(BackendVersions).map((version) => (
        <option key={version} value={version}>
          {version}
        </option>
      ))}
    </Select>
  );
};

export default Dropdown;

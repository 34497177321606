import React, { useContext } from "react";
import { AppContext } from "../../AppProvider";
import { BackendVersions } from "../../const/backendVersions";
import TeamsAppVersion4_0_xForm from "./forms/TeamsAppVersion4_0_xForm";
import TeamsAppVersion4_3_xForm from "./forms/TeamsAppVersion4_3_xForm";

const TeamsAppManifest: React.FC = () => {
  const { selectedVersion } = useContext(AppContext);

  switch (selectedVersion) {
  case BackendVersions["4.0.x"]:
    return <TeamsAppVersion4_0_xForm />;
  case BackendVersions["4.1.x"]:
    return <TeamsAppVersion4_0_xForm />;
  case BackendVersions["4.3.x"]:
    return <TeamsAppVersion4_3_xForm />;
  case BackendVersions["4.4.x"]:
    return <TeamsAppVersion4_3_xForm />;
  case BackendVersions["4.5.x"]:
    return <TeamsAppVersion4_3_xForm />;
  case BackendVersions["4.6.x"]:
    return <TeamsAppVersion4_3_xForm />;
  case BackendVersions["4.7.x"]:
    return <TeamsAppVersion4_3_xForm />;
  case BackendVersions["4.8.x"]:
    return <TeamsAppVersion4_3_xForm />;
  case BackendVersions["4.9.x"]:
    return <TeamsAppVersion4_3_xForm />;
  default:
    return (
      <div>
        <p>No version selected</p>
      </div>
    );
  }
};

export default TeamsAppManifest;
/* eslint-disable max-len */
import React, { useContext } from "react";
import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { menuItems } from "../const/menu";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppProvider";

const Card: React.FC<IProps> = ({ title, description, path, iconPath }) => {
  const navigate = useNavigate();
  const { isDarkMode } = useContext(AppContext);

  return (
    <Box
      maxW="container.sm"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      m="10px"
    >
      <Box p="6">
        <Image
          boxSize='75px'
          src={iconPath}
          alt={title}
          mb={3} ml={1}
        />
        <Text fontWeight="bold" color={isDarkMode ? "blue.300" : "facebook.600"} fontSize={18} lineHeight="tight" isTruncated>
          {title}
        </Text>
        <Text color={isDarkMode ? "whitesmoke" : "gray.500"} mt="1">
          {description}
        </Text>
        <Button
          rightIcon={<ArrowForwardIcon />}
          colorScheme='blue'
          mt={5}
          size="sm"
          variant="ghost"
          onClick={() => navigate(path)}
        >
          Create Config
        </Button>
      </Box>
    </Box>
  );
};

const Cards = () => (
  <Flex
    mx="auto"
    mt="10"
    flexWrap="wrap"
    width="100%"
    maxW="90vw"
    justifyContent="center"
    alignItems="center"
  >
    {
      menuItems.map((menu) => (
        <Card key={menu.path} {...menu} />
      ))
    }
  </Flex>
);

export default Cards;

export interface IProps {
  title: string;
  path: string;
  iconPath: string;
  description: string;
}
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import {
  Flex, FormControl, FormLabel, Tooltip,
  Input, FormErrorMessage, Button, Box, Grid, GridItem, useBreakpointValue, Switch,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import OutputPage, { IFileType } from "../../../components/OutputPage";
import { urlRegex } from "../../../helpers/inputValidatorRegex";
import { teamsAppTemplateV4_3 } from "../../../const/templates/teamsAppTemplate";
import { getReplacerRegex, onlyUnique, regEx } from "../../../helpers/replacerRegex";
import FormHeader from "../../../components/form/FormHeader";
import FormContainer from "../../../components/form/FormContainer";
import colorImageFile from "../../../const/files/teamsBot/color.png";
import outlineImageFile from "../../../const/files/teamsBot/outline.png";

const TeamsAppVersion4_3_xForm = () => {
  const [values, setValues] = useState({
    WebAppId: "",
    UniqueId: "ecca365c-340f-40d0-bdfe-16f679066763",
    BotId: "",
    Resource: "",
    ValidDomain: "",
    WebSiteUrl: "",
  });
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [otherFiles, setOtherFiles] = useState<IFileType[]>([]);
  const [showBotId, setShowBotId] = useState(false);
  const navigate = useNavigate();
  const isSmall = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    (async () => {
      const colorImage = await (await fetch(colorImageFile)).blob();
      const outlineImage = await (await fetch(outlineImageFile)).blob();
      setOtherFiles([
        { name: "color.png", data: colorImage },
        { name: "outline.png", data: outlineImage }
      ]);
    })();
  }, []);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name !== "UniqueId") {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  }, []);

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    let data = teamsAppTemplateV4_3(showBotId, values); // Call the function with appropriate arguments
    const placeHolders = [...data.match(regEx) as RegExpMatchArray].filter(onlyUnique);
    const url = new URL(`${values.WebSiteUrl}`);
    const siteName = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    const value = { ...values, origin: url.origin, site: siteName };
    const _values: Record<string, any> = value;
    for (const field of placeHolders) {
      if (_values[field]) {
        data = data.replace(getReplacerRegex(field), _values[field]);
      } else {
        data = data.replace(getReplacerRegex(field), "");
      }
    }
    setLoading(false);
    setResult(data);
    setResultVisible(true);

  }, [showBotId, values]);

  const handleChange = () => {
    setShowBotId(!showBotId);
  };

  const isError = !values.WebAppId.trim().length || !values.UniqueId.trim().length ||
    !values.Resource.trim().length || !values.ValidDomain.trim().length ||
    !values.WebSiteUrl.trim().length || !urlRegex.test(values.WebSiteUrl);

  if (resultVisible) {
    return (
      <OutputPage
        title="Teams App Manifest"
        content={result}
        otherFiles={otherFiles}
        fileName="Dock-Contract-TeamsApp-Manifest.json"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Teams App Manifest" />
      <form onSubmit={onSubmit} >
        <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
          <GridItem h='10' my={6}>
            <FormControl isInvalid={!values.WebAppId.trim().length}>
              <FormLabel htmlFor="WebAppId">Application ID</FormLabel>
              <Tooltip label='Unique Web Application' placement='top-end'>
                <Input
                  id="WebAppId"
                  name="WebAppId"
                  value={values.WebAppId}
                  onChange={onChange}
                  autoComplete="off"
                />
              </Tooltip>
              {!values.WebAppId.trim().length && <FormErrorMessage>Application ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={6}>
            <FormControl isInvalid={!values.UniqueId.trim().length}>
              <FormLabel htmlFor="UniqueId">ID</FormLabel>
              <Tooltip label='Unique GUID of App' placement='top-end'>
                <Input
                  id="UniqueId"
                  name="UniqueId"
                  value={values.UniqueId}
                  onChange={onChange}
                  autoComplete="off"
                />
              </Tooltip>
              {!values.UniqueId.trim().length && <FormErrorMessage>ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>


          <GridItem h='10' my={6}>
            <FormControl isInvalid={!values.ValidDomain.trim().length}>
              <FormLabel htmlFor="ValidDomain">Domain Name</FormLabel>
              <Tooltip
                label='Domain name of site. eg: mydock.sharepoint.com'
                placement='top-end'
              >
                <Input
                  id="ValidDomain"
                  name="ValidDomain"
                  value={values.ValidDomain}
                  onChange={onChange}
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ValidDomain.trim().length && <FormErrorMessage>Domain Name is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={6}>
            <FormControl isInvalid={!values.Resource.trim().length}>
              <FormLabel htmlFor="Resource">Resource</FormLabel>
              <Tooltip
                label='Resource of app. eg: https://mydock.sharepoint.com'
                placement='top-end'
              >
                <Input
                  id="Resource"
                  name="Resource"
                  value={values.Resource}
                  onChange={onChange}
                  autoComplete="off"
                />
              </Tooltip>
              {!values.Resource.trim().length && <FormErrorMessage>Resource is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={6}>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="botIdToggle" mb="0">
                Bot
              </FormLabel>
              <Switch id="botIdToggle" isChecked={showBotId} onChange={handleChange} />
            </FormControl>


            {showBotId && (
              <FormControl isInvalid={!values.BotId.trim().length}>
                <FormLabel htmlFor="BotId">Bot ID</FormLabel>
                <Tooltip
                  label='ID of azure bot, eg: eedfd0e3-d414-4984-9662-f23df56cca0a'
                  placement='top-end'
                >
                  <Input
                    id="BotId"
                    name="BotId"
                    value={values.BotId}
                    onChange={onChange}
                    autoComplete="off"
                  />
                </Tooltip>
                {!values.BotId.trim().length && <FormErrorMessage>Bot ID is required.</FormErrorMessage>}
              </FormControl>
            )}
          </GridItem>


          <GridItem h='10' my={6}>
            <FormControl isInvalid={!values.WebSiteUrl.trim().length || !urlRegex.test(values.WebSiteUrl)}>
              <FormLabel htmlFor="WebSiteUrl">Website Url</FormLabel>
              <Tooltip
                label='Website Url. eg: https://mydock.sharepoint.com/sites/ContractManagementDemo'
                placement='top-end'
              >
                <Input
                  id="WebSiteUrl"
                  name="WebSiteUrl"
                  value={values.WebSiteUrl}
                  onChange={onChange}
                  autoComplete="off"
                />
              </Tooltip>
              {!values.WebSiteUrl.trim().length ? <FormErrorMessage>Website Url is required.</FormErrorMessage> :
                !urlRegex.test(values.WebSiteUrl) && <FormErrorMessage>Valid Website Url is required.</FormErrorMessage>
              }
            </FormControl>
          </GridItem>
        </Grid>
        <Box>

          <Flex justifyContent={isSmall ? "center" : "flex-end"} gap={5} my={10}>
            <Button
              mt={4}
              variant="outline"
              colorScheme='facebook'
              width={isSmall ? "45%" : "32"}
              disabled={loading}
              onClick={() => { navigate("/"); }}>
              Back
            </Button>
            <Button size='md'
              colorScheme='facebook'
              type="submit" mt={4}
              width={isSmall ? "45%" : "32"}
              isDisabled={isError}>
              Create
            </Button>
          </Flex>
        </Box>
      </form>
    </FormContainer >
  );
};

export default TeamsAppVersion4_3_xForm;
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect } from "react";
import { useFormik } from "formik";
import {
  FormControl, FormLabel, Input, FormErrorMessage, Flex,
  Box,
  Button,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import FormHeader from "../../components/form/FormHeader";
import FormContainer from "../../components/form/FormContainer";
import FormGrid from "../../components/form/FormGrid";
import { AppContext } from "../../AppProvider";

const LoginForm: React.FC = () => {
  const { setIsLoggedIn } = useContext(AppContext);
  const toast = useToast();
  const isValidEmail = useCallback((email: string) => {
    return email.endsWith("@global-infonet.com");
  }, []);

  // const generateExpectedUnknown = useCallback(() => {
  //   const now = new Date();
  //   let hour = now.getHours();
  //   hour = (hour % 12) || 12;
  //   const minute = now.getMinutes();
  //   return `Yxl-TmlhW6#${hour}${minute}`;
  // }, []);
  const generateExpectedUnknown = useCallback(() => {
    const now = new Date();
    let hour = now.getHours();
    hour = (hour % 12) || 12;
    const minute = now.getMinutes();
    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");
    return `Yxl-TmlhW6#${formattedHour}${formattedMinute}`;
  }, []);
  

  const onSubmitForm = useCallback((e: any) => {
    if (!isValidEmail(e?.USERNAME)) {
      toast({
        title: "Invalid credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const expectedUnknown = generateExpectedUnknown();
    if (e.PASSWORD === expectedUnknown) {
      toast({
        title: "Login successful",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsLoggedIn(true);
      sessionStorage.setItem("isLoggedInDH2", "true");
    } else {
      toast({
        title: "Invalid credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, []);

  const {
    errors, touched, values, handleChange, handleBlur, handleSubmit, setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
  });

  return (
    <FormContainer>
      <FormHeader title="Login" />
      <form onSubmit={handleSubmit} onChange={handleChange} onBlur={handleBlur}>
        <FormGrid>
          <FormControl isInvalid={errors.USERNAME && touched.USERNAME || false}>
            <FormLabel htmlFor="USERNAME">User Name</FormLabel>
            <Input
              id="USERNAME"
              name="USERNAME"
              defaultValue={values.USERNAME}
              size="sm"
              autoComplete="off"
              required
            />
            {errors.USERNAME && <FormErrorMessage>{errors.USERNAME}</FormErrorMessage>}
          </FormControl>


          <FormControl isInvalid={errors.PASSWORD && touched.PASSWORD || false}>
            <FormLabel htmlFor="PASSWORD">Password</FormLabel>
            <Input
              id="PASSWORD"
              name="PASSWORD"
              defaultValue={values.PASSWORD}
              size="sm"
              autoComplete="off"
              required
              type="password"
            />
            {errors.PASSWORD && <FormErrorMessage>{errors.PASSWORD}</FormErrorMessage>}
          </FormControl>

        </FormGrid>
        <Box>
          <Flex
            justifyContent="center"
            gap={5}
            mb={20}
          >
            <Button
              size="md"
              colorScheme="facebook"
              type="submit"
              mt={4}
            >
              Login
            </Button>
          </Flex>
        </Box>
      </form>
    </FormContainer >
  );
};

export default LoginForm;

const initialValues = {
  USERNAME: "",
  PASSWORD: "",
};

const validationSchema = Yup.object().shape({
  USERNAME: Yup.string().required(),
  PASSWORD: Yup.string().required(),
});

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useCallback, useContext, useState } from "react";
import {
  Flex, GridItem, FormControl, FormLabel,
  Tooltip, Input, FormErrorMessage, Button, Grid, Box,
} from "@chakra-ui/react";
import OutputPage from "../../../components/OutputPage";
import { useNavigate } from "react-router-dom";
import { backendServiceTemplateV4_1 } from "../../../const/templates/backendServiceTemplate";
import { regEx, onlyUnique, getReplacerRegex } from "../../../helpers/replacerRegex";
import { AppContext } from "../../../AppProvider";
import FormHeader from "../../../components/form/FormHeader";
import FormContainer from "../../../components/form/FormContainer";

const Version4_1_xForm = () => {
  const [values, setValues] = useState({
    RedisBuild: 356,
    BuildId: undefined,
    Origin: "",
    BaseUrl: "",
    SiteUrl: "",
    ClientId: "",
    ClientSecret: "",
  });
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { isSmall } = useContext(AppContext);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data = backendServiceTemplateV4_1;
    const placeHolders = [...data.match(regEx) as RegExpMatchArray].filter(onlyUnique);
    const _values: Record<string, any> = values;
    for (const field of placeHolders) {
      if (_values[field]) {
        data = data.replace(getReplacerRegex(field), _values[field]);
      } else {
        data = data.replace(getReplacerRegex(field), "");
      }
    }
    setLoading(false);
    setResult(data);
    setResultVisible(true);
  }, [values]);

  //⛔ Validate Required fields To enable submit button
  const isError = !values.RedisBuild || !values.BuildId || !values.Origin.trim().length || !values.BaseUrl.trim().length ||
    !values.SiteUrl.trim().length || !values.ClientId.trim().length || !values.ClientSecret.trim().length;


  if (resultVisible) {
    return (
      <OutputPage
        title="Backend Service Config - V4.1.x"
        content={result}
        fileName="Dock-Contract-BackendService-Config.yaml"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Backend Service Config - V4.1.x" />
      <form onSubmit={onSubmit} >
        <Grid templateColumns={isSmall ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap={6} minW={isSmall ? "90vw" : "70vw"}>
          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.RedisBuild}>
              <FormLabel htmlFor="RedisBuild">Redis Build ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="RedisBuild"
                  name="RedisBuild"
                  type="number"
                  value={values.RedisBuild}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.RedisBuild && <FormErrorMessage>Redis Build ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.BuildId}>
              <FormLabel htmlFor="BuildId">Backend Build ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="BuildId"
                  name="BuildId"
                  type="number"
                  value={values.BuildId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.BuildId && <FormErrorMessage>Backend Build ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.Origin.trim().length}>
              <FormLabel htmlFor="Origin">Origin Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="Origin"
                  name="Origin"
                  value={values.Origin}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.Origin.trim().length && <FormErrorMessage>Origin Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.BaseUrl.trim().length}>
              <FormLabel htmlFor="BaseUrl">Base Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="BaseUrl"
                  name="BaseUrl"
                  value={values.BaseUrl}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.BaseUrl.trim().length && <FormErrorMessage>Base Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.SiteUrl.trim().length}>
              <FormLabel htmlFor="SiteUrl">Site Url</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="SiteUrl"
                  name="SiteUrl"
                  value={values.SiteUrl}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.SiteUrl.trim().length && <FormErrorMessage>Site Url is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.ClientId.trim().length}>
              <FormLabel htmlFor="ClientId">Client ID</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="ClientId"
                  name="ClientId"
                  value={values.ClientId}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ClientId.trim().length && <FormErrorMessage>Client ID is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>

          <GridItem h='10' my={4}>
            <FormControl isInvalid={!values.ClientSecret.trim().length}>
              <FormLabel htmlFor="ClientSecret">Client Secret</FormLabel>
              <Tooltip label='' placement='top-end'>
                <Input
                  id="ClientSecret"
                  name="ClientSecret"
                  value={values.ClientSecret}
                  onChange={onChange}
                  size="sm"
                  autoComplete="off"
                />
              </Tooltip>
              {!values.ClientSecret.trim().length && <FormErrorMessage>Client Secret is required.</FormErrorMessage>}
            </FormControl>
          </GridItem>
        </Grid>

        {/*⛔ Footer Buttons */}
        <Box>
          <Flex justifyContent={isSmall ? "center" : "flex-end"} gap={5} my={10}>
            <Button
              mt={4}
              variant="outline"
              colorScheme='facebook'
              width={isSmall ? "45%" : "32"}
              disabled={loading}
              onClick={() => { navigate("/"); }}>
              Back
            </Button>
            <Button size='md'
              colorScheme='facebook'
              type="submit" mt={4}
              width={isSmall ? "45%" : "32"}
              isDisabled={isError}>
              Create
            </Button>
          </Flex>
        </Box>
      </form>
    </FormContainer >
  );
};

export default Version4_1_xForm;
import React, { useContext } from "react";
import { AppContext } from "../../AppProvider";
import { BackendVersions } from "../../const/backendVersions";
import Version4_0_xForm from "./forms/Version4_0_xForm";
import Version4_1_xForm from "./forms/Version4_1_xForm";
import Version4_3_xForm from "./forms/Version4_3_xForm";
import Version4_4_xForm from "./forms/Version4_4_xForm";
import Version4_5_xForm from "./forms/Version4_5_xForm";
import Version4_6_xForm from "./forms/Version4_6_xForm";
import Version4_7_xForm from "./forms/Version4_7_xForm";
import Version4_8_xForm from "./forms/Version4_8_xForm";
import Version4_9_xForm from "./forms/Version4_9_xForm";


const BackendServicePage: React.FC = () => {
  const { selectedVersion } = useContext(AppContext);

  switch (selectedVersion) {
  case BackendVersions["4.0.x"]:
    return <Version4_0_xForm />;
  case BackendVersions["4.1.x"]:
    return <Version4_1_xForm />;
  case BackendVersions["4.3.x"]:
    return <Version4_3_xForm />;
  case BackendVersions["4.4.x"]:
    return <Version4_4_xForm />;
  case BackendVersions["4.5.x"]:
    return <Version4_5_xForm />;
  case BackendVersions["4.6.x"]:
    return <Version4_6_xForm />;
  case BackendVersions["4.7.x"]:
    return <Version4_7_xForm />;
  case BackendVersions["4.8.x"]:
    return <Version4_8_xForm />;
  case BackendVersions["4.9.x"]:
    return <Version4_9_xForm />;
  default:
    return (
      <div>
        <p>No version selected</p>
      </div>
    );
  }
};

export default BackendServicePage;
import React, { useCallback, useContext } from "react";
import { Image, Flex, Text, useBreakpointValue, Button } from "@chakra-ui/react";
import VersionDropdown from "./VersionDropdown";
import { useNavigate } from "react-router-dom";
import DarkModeToggle from "./DarkModeToggle";
import { AppContext } from "../../AppProvider";

const Navbar = () => {
  const { isLoggedIn } = useContext(AppContext);
  const navigate = useNavigate();
  const isSmall = useBreakpointValue({ base: true, md: false });

  const onloggout = useCallback(() => {
    sessionStorage.removeItem("isLoggedInDH2");
    navigate("/login");
  }, [navigate]);

  return (
    <Flex
      bg="facebook.600"
      py={2}
      px={5}
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      top={0}
      zIndex={100}
      shadow="md"
    >
      <Flex
        p={2}
        gap={1}
        justifyContent="flex-start"
        alignItems="center"
        cursor="pointer"
      >
        <Image src="dock_white.svg" alt="Logo" height={isSmall ? "2.5rem" : "3rem"} />
        {/* <Image
          src="https://www.svgrepo.com/show/303180/microsoft-teams-logo.svg"
          alt="Logo"
          height={isSmall ? "2.5rem" : "3rem"}
        /> */}
        <Text
          fontSize={isSmall ? "md" : "lg"}
          fontWeight="bold"
          color={"whiteAlpha.900"}
          onClick={() => isLoggedIn ? navigate("/") : navigate("/login")}
        >
          Deployment Helper
        </Text>
      </Flex>
      <Flex p={2} gap={2} justifyContent="flex-start" alignItems="center">
        {isLoggedIn &&
          <Text fontSize="medium" color={"whiteAlpha.900"}>
            Version
          </Text>
        }
        {isLoggedIn && <VersionDropdown />}
        {isLoggedIn && <Button px={8} onClick={onloggout}>Logout</Button>}

        <DarkModeToggle />
      </Flex>
    </Flex>
  );
};

export default Navbar;

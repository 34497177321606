/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from "react";
import {
  FormControl, FormLabel, Input, Stack, Button, Flex, Tooltip,
  FormErrorMessage, Alert, AlertDescription, AlertIcon,
} from "@chakra-ui/react";
import { getReplacerRegex, onlyUnique, regEx } from "../../helpers/replacerRegex";
import OutputPage from "../../components/OutputPage";
import { useNavigate } from "react-router-dom";
import { outlookAddInTemplate } from "../../const/templates/outlookAddInTemplate";
import FormHeader from "../../components/form/FormHeader";
import FormContainer from "../../components/form/FormContainer";

const OutlookAddInPage = () => {
  const [values, setValues] = useState({
    ApplicationId: "",
    Version: "",
    BackendDomain: "",
  });
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    let data = outlookAddInTemplate;
    const placeHolders = [...data.match(regEx) as RegExpMatchArray].filter(onlyUnique);
    for (const field of placeHolders) {
      const _values: Record<string, any> = values;
      if (values) {
        data = data.replace(getReplacerRegex(field), _values[field]);
      } else {
        data = data.replace(getReplacerRegex(field), "");
      }
    }
    setLoading(false);
    setResult(data);
    setResultVisible(true);
  }, [values]);

  const isError = !values.ApplicationId.trim().length ||
    !values.Version.trim().length || !values.BackendDomain.trim().length;

  if (resultVisible) {
    return (
      <OutputPage
        title="Word Add-In Manifest"
        content={result}
        fileName="Dock-Contract-WordAddIn-Manifest.xml"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Outlook Add-In Manifest" />
      <form onSubmit={onSubmit} >
        <Stack w={["100%", "100%"]} boxSize={["sm", "lg"]}>

          <FormControl isInvalid={!values.ApplicationId.trim().length}>
            <FormLabel htmlFor="ApplicationId">Application ID</FormLabel>
            <Tooltip label='Application Id of the App registration in Azure Portal' placement='top-end'>
              <Input
                id="ApplicationId"
                name="ApplicationId"
                value={values.ApplicationId}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.ApplicationId.trim().length && <FormErrorMessage>Application ID is required.</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={!values.Version.trim().length}>
            <Alert status='info'>
              <AlertIcon />
              <AlertDescription fontSize={13}>
                Updates from the store will only get triggered if there is a version change!
              </AlertDescription>
            </Alert>
            <FormLabel htmlFor="Version">Version</FormLabel>
            <Tooltip label='Version of Add-In eg: 4.0.9.0' placement='top-end'>
              <Input
                id="Version"
                name="Version"
                value={values.Version}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.Version.trim().length && <FormErrorMessage>Version is required.</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={!values.BackendDomain.trim().length}>
            <FormLabel htmlFor="BackendDomain">Backend Domain</FormLabel>
            <Tooltip
              label='Domain name of backend service. eg: d365-backend-services.azurewebsites.net'
              placement='top-end'
            >
              <Input
                id="BackendDomain"
                name="BackendDomain"
                value={values.BackendDomain}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.BackendDomain.trim().length && <FormErrorMessage>Backend Domain is required.</FormErrorMessage>}
          </FormControl>

          <Flex justifyContent="center" gap={5}>
            <Button mt={4} variant="outline" colorScheme='facebook'
              width="45%" disabled={loading} onClick={() => { navigate("/"); }}>
              Back
            </Button>
            <Button size='md' colorScheme='facebook' type="submit" mt={4}
              width="45%" isDisabled={isError}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormContainer>
  );
};

export default OutlookAddInPage;
